import { useState } from 'react';
import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Button from './ui/Button';
import Logo from './ui/Logo';
import MainNav from './navigation/MainNav';
import MobileNav from './navigation/MobileNav';

export default function Navigation() {
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 10);
    };
    
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header
      className={`fixed inset-x-0 top-0 z-50 transition-all duration-300 ${
        scrolled
          ? 'bg-white/90 backdrop-blur-md shadow-sm'
          : 'bg-white/50 backdrop-blur-sm'
      }`}
    >
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between p-4 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <Logo className="-m-1.5 p-1.5" />
        </div>
        
        <MobileNav />
        
        <div className="hidden lg:flex lg:gap-x-12">
          <MainNav />
        </div>

        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <Link to="/contact">
            <Button
              size="sm"
              className="transition-transform hover:-translate-y-0.5"
            >
              Get Started
            </Button>
          </Link>
        </div>
      </nav>
    </header>
  );
}