import { useState } from 'react';
import { Search } from 'lucide-react';
import FeaturedCarriers from '../../components/carriers/FeaturedCarriers';
import CarrierCard from '../../components/carriers/CarrierCard';

const carriers = [
  {
    name: 'Pinnacle Life & Health',
    logo: 'https://images.unsplash.com/photo-1560179707-f14e90ef3623?w=200&h=100&fit=crop&auto=format',
    rating: 'A+ (Superior)',
    specialties: ['Life Insurance', 'Medicare Solutions', 'Group Benefits'],
    partnerLevel: 'Elite Partner',
    features: [
      'Comprehensive product portfolio',
      'Advanced underwriting platform',
      'Industry-leading commission rates',
      'Dedicated support team'
    ]
  },
  {
    name: 'Guardian Shield Insurance',
    logo: 'https://images.unsplash.com/photo-1560179707-f14e90ef3623?w=200&h=100&fit=crop&auto=format',
    rating: 'A (Excellent)',
    specialties: ['Final Expense', 'Term Life', 'Critical Illness'],
    partnerLevel: 'Premier Partner',
    features: [
      'Simplified issue products',
      'Next-day policy issue',
      'Electronic applications',
      'Agent marketing support'
    ]
  },
  {
    name: 'Liberty Health Solutions',
    logo: 'https://images.unsplash.com/photo-1560179707-f14e90ef3623?w=200&h=100&fit=crop&auto=format',
    rating: 'A (Excellent)',
    specialties: ['Medicare Advantage', 'Supplement Plans', 'Prescription Coverage'],
    partnerLevel: 'Strategic Partner',
    features: [
      'Competitive Medicare products',
      'Year-round enrollment tools',
      'Compliance support',
      'Training resources'
    ]
  }
];

export default function CarrierDirectoryPage() {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedSpecialty, setSelectedSpecialty] = useState('all');

  const filteredCarriers = carriers.filter((carrier) => {
    const matchesSearch = carrier.name.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesSpecialty = selectedSpecialty === 'all' || carrier.specialties.includes(selectedSpecialty);
    return matchesSearch && matchesSpecialty;
  });

  return (
    <main className="min-h-screen bg-gray-50 pt-16">
      <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8">
        {/* Page Header */}
        <div className="text-center">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900">
            Carrier Directory
          </h1>
          <p className="mx-auto mt-6 max-w-2xl text-lg text-gray-600">
            Explore our network of trusted insurance carriers and find the perfect
            products for your clients.
          </p>
        </div>

        {/* Search and Filter */}
        <div className="mt-12 flex flex-col gap-4 sm:flex-row sm:items-center sm:justify-between">
          <div className="relative flex-1">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <Search className="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="text"
              placeholder="Search carriers..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="block w-full rounded-lg border-gray-300 pl-10 focus:border-blue-500 focus:ring-blue-500"
            />
          </div>
          
          <select
            value={selectedSpecialty}
            onChange={(e) => setSelectedSpecialty(e.target.value)}
            className="rounded-lg border-gray-300 focus:border-blue-500 focus:ring-blue-500"
          >
            <option value="all">All Specialties</option>
            <option value="Life Insurance">Life Insurance</option>
            <option value="Medicare Solutions">Medicare Solutions</option>
            <option value="Final Expense">Final Expense</option>
          </select>
        </div>

        {/* Featured Carriers */}
        <FeaturedCarriers />

        {/* All Carriers */}
        <div className="mt-16">
          <h2 className="text-2xl font-bold text-gray-900">All Carriers</h2>
          <div className="mt-8 grid gap-8 md:grid-cols-2 lg:grid-cols-3">
            {filteredCarriers.map((carrier) => (
              <CarrierCard key={carrier.name} carrier={carrier} />
            ))}
          </div>
        </div>
      </div>
    </main>
  );
}