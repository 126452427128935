import { Github, Linkedin, Twitter } from 'lucide-react';
import { LEADERSHIP_TEAM } from '../../../constants';

export default function TeamProfiles() {
  return (
    <section className="bg-gray-50 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Leadership Team
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Meet the experienced professionals leading UIP's mission to transform
            insurance distribution.
          </p>
        </div>

        <ul className="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-4">
          {LEADERSHIP_TEAM.map((profile) => (
            <li
              key={profile.name}
              className="group relative rounded-3xl bg-white p-8 shadow-md transition-all hover:shadow-xl"
            >
              <div className="aspect-[3/2] overflow-hidden rounded-2xl bg-gray-100">
                <img
                  className="h-full w-full object-cover transition-transform duration-300 group-hover:scale-105"
                  src={profile.image}
                  alt={profile.name}
                />
              </div>
              <h3 className="mt-6 text-lg font-semibold leading-7 tracking-tight text-gray-900">
                {profile.name}
              </h3>
              <p className="text-sm leading-6 text-blue-600">{profile.role}</p>
              <p className="mt-4 text-sm leading-6 text-gray-600">{profile.bio}</p>
              
              {profile.achievements && (
                <div className="mt-6 space-y-2">
                  <h4 className="text-sm font-medium text-gray-900">Key Achievements</h4>
                  <ul className="list-disc pl-4 text-sm text-gray-600">
                    {profile.achievements.map((achievement, index) => (
                      <li key={index}>{achievement}</li>
                    ))}
                  </ul>
                </div>
              )}
              
              <ul className="mt-6 flex gap-4">
                {profile.social.linkedin && (
                  <li>
                    <a
                      href={profile.social.linkedin}
                      className="text-gray-400 hover:text-blue-600 transition-colors"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Linkedin className="h-5 w-5" />
                    </a>
                  </li>
                )}
                {profile.social.twitter && (
                  <li>
                    <a
                      href={profile.social.twitter}
                      className="text-gray-400 hover:text-blue-600 transition-colors"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Twitter className="h-5 w-5" />
                    </a>
                  </li>
                )}
                {profile.social.github && (
                  <li>
                    <a
                      href={profile.social.github}
                      className="text-gray-400 hover:text-blue-600 transition-colors"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Github className="h-5 w-5" />
                    </a>
                  </li>
                )}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}