import { FileText, Download, Book } from 'lucide-react';
import Button from '../../components/ui/Button';

const documents = {
  forms: [
    { name: 'Life Insurance Application', type: 'PDF', size: '245 KB' },
    { name: 'Health Insurance Enrollment', type: 'PDF', size: '312 KB' },
    { name: 'Medicare Supplement Forms', type: 'PDF', size: '180 KB' },
    { name: 'Agent Contracting Package', type: 'PDF', size: '425 KB' },
  ],
  guides: [
    { name: 'Product Training Manual', type: 'PDF', size: '2.4 MB' },
    { name: 'Underwriting Guidelines', type: 'PDF', size: '1.8 MB' },
    { name: 'Compliance Handbook', type: 'PDF', size: '3.2 MB' },
    { name: 'Marketing Guidelines', type: 'PDF', size: '1.5 MB' },
  ],
  marketing: [
    { name: 'Sales Presentation Templates', type: 'PPTX', size: '4.8 MB' },
    { name: 'Product Brochures', type: 'PDF', size: '8.2 MB' },
    { name: 'Email Templates', type: 'DOCX', size: '725 KB' },
    { name: 'Social Media Assets', type: 'ZIP', size: '15.6 MB' },
  ],
};

export default function DocumentsPage() {
  return (
    <main className="min-h-screen bg-gray-50 pt-16">
      <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8">
        {/* Page Header */}
        <div className="text-center">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900">
            Document Center
          </h1>
          <p className="mx-auto mt-6 max-w-2xl text-lg text-gray-600">
            Access all the forms, guides, and marketing materials you need to run
            your business effectively.
          </p>
        </div>

        {/* Document Categories */}
        <div className="mt-16 grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          {/* Forms & Applications */}
          <div className="rounded-2xl bg-white p-8 shadow-lg ring-1 ring-gray-200/50">
            <div className="flex items-center gap-4">
              <div className="rounded-lg bg-blue-50 p-2 text-blue-600">
                <FileText className="h-6 w-6" />
              </div>
              <h2 className="text-xl font-semibold text-gray-900">
                Forms & Applications
              </h2>
            </div>

            <ul className="mt-8 space-y-4">
              {documents.forms.map((doc) => (
                <li
                  key={doc.name}
                  className="flex items-center justify-between rounded-lg bg-gray-50 p-4 transition-colors hover:bg-gray-100"
                >
                  <div>
                    <p className="font-medium text-gray-900">{doc.name}</p>
                    <p className="text-sm text-gray-500">
                      {doc.type} • {doc.size}
                    </p>
                  </div>
                  <Button variant="outline" size="sm">
                    <Download className="h-4 w-4" />
                  </Button>
                </li>
              ))}
            </ul>
          </div>

          {/* Product Guides */}
          <div className="rounded-2xl bg-white p-8 shadow-lg ring-1 ring-gray-200/50">
            <div className="flex items-center gap-4">
              <div className="rounded-lg bg-blue-50 p-2 text-blue-600">
                <Book className="h-6 w-6" />
              </div>
              <h2 className="text-xl font-semibold text-gray-900">
                Product Guides
              </h2>
            </div>

            <ul className="mt-8 space-y-4">
              {documents.guides.map((doc) => (
                <li
                  key={doc.name}
                  className="flex items-center justify-between rounded-lg bg-gray-50 p-4 transition-colors hover:bg-gray-100"
                >
                  <div>
                    <p className="font-medium text-gray-900">{doc.name}</p>
                    <p className="text-sm text-gray-500">
                      {doc.type} • {doc.size}
                    </p>
                  </div>
                  <Button variant="outline" size="sm">
                    <Download className="h-4 w-4" />
                  </Button>
                </li>
              ))}
            </ul>
          </div>

          {/* Marketing Materials */}
          <div className="rounded-2xl bg-white p-8 shadow-lg ring-1 ring-gray-200/50">
            <div className="flex items-center gap-4">
              <div className="rounded-lg bg-blue-50 p-2 text-blue-600">
                <FileText className="h-6 w-6" />
              </div>
              <h2 className="text-xl font-semibold text-gray-900">
                Marketing Materials
              </h2>
            </div>

            <ul className="mt-8 space-y-4">
              {documents.marketing.map((doc) => (
                <li
                  key={doc.name}
                  className="flex items-center justify-between rounded-lg bg-gray-50 p-4 transition-colors hover:bg-gray-100"
                >
                  <div>
                    <p className="font-medium text-gray-900">{doc.name}</p>
                    <p className="text-sm text-gray-500">
                      {doc.type} • {doc.size}
                    </p>
                  </div>
                  <Button variant="outline" size="sm">
                    <Download className="h-4 w-4" />
                  </Button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </main>
  );
}