import { useEffect } from 'react';

/**
 * Hook that disables body scroll when a condition is true
 * @param disabled Whether to disable scrolling
 */
export const useDisableScroll = (disabled: boolean): void => {
  useEffect(() => {
    if (disabled) {
      // Save current scroll position
      const scrollY = window.scrollY;
      
      // Add styles to prevent scrolling
      document.body.style.position = 'fixed';
      document.body.style.top = `-${scrollY}px`;
      document.body.style.width = '100%';
      document.body.style.overflowY = 'scroll';

      return () => {
        // Remove styles and restore scroll position
        document.body.style.position = '';
        document.body.style.top = '';
        document.body.style.width = '';
        document.body.style.overflowY = '';
        window.scrollTo(0, scrollY);
      };
    }
  }, [disabled]);
};

export default useDisableScroll;
