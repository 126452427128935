import { Search, Home, ArrowLeft } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../../components/ui/Button';

const popularLinks = [
  { name: 'Life Insurance', href: '/products/life' },
  { name: 'Medicare Solutions', href: '/products/medicare' },
  { name: 'Agent Benefits', href: '/agents/benefits' },
  { name: 'Contact Us', href: '/contact' },
];

export default function NotFoundPage() {
  const navigate = useNavigate();

  return (
    <main className="grid min-h-screen place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <p className="text-9xl font-semibold text-blue-600">404</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          Page not found
        </h1>
        <p className="mt-6 text-base leading-7 text-gray-600">
          Sorry, we couldn't find the page you're looking for.
        </p>
        
        {/* Popular Links */}
        <div className="mt-10">
          <h2 className="text-sm font-semibold text-gray-900">
            Popular Resources
          </h2>
          <ul className="mt-4 flex flex-wrap justify-center gap-4">
            {popularLinks.map((link) => (
              <li key={link.name}>
                <Link
                  to={link.href}
                  className="rounded-full bg-gray-50 px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-100"
                >
                  {link.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>

        {/* Action Buttons */}
        <div className="mt-10 flex items-center justify-center gap-6">
          <Button
            onClick={() => navigate(-1)}
            variant="outline"
            className="gap-2"
          >
            <ArrowLeft className="h-4 w-4" />
            Go Back
          </Button>
          
          <Link to="/">
            <Button className="gap-2">
              <Home className="h-4 w-4" />
              Return Home
            </Button>
          </Link>
        </div>
      </div>
    </main>
  );
}