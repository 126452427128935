import { CULTURE_PILLARS } from '../../../constants/company-values';
import { ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import Button from '../../ui/Button';

export default function Culture() {
  return (
    <section className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Our Family Culture
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            At UIP, we're more than just a company – we're a family of dedicated
            professionals supporting each other's success.
          </p>
        </div>

        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 lg:max-w-none lg:grid-cols-3">
          {CULTURE_PILLARS.map((pillar) => (
            <div
              key={pillar.title}
              className="group relative rounded-2xl bg-white p-8 shadow-md ring-1 ring-gray-200/50 transition-all hover:shadow-lg"
            >
              <div className="absolute inset-0 bg-gradient-to-br from-blue-50/50 via-white to-white opacity-0 transition-opacity group-hover:opacity-100" />
              
              <div className="relative">
                <h3 className="text-xl font-semibold leading-7 text-gray-900">
                  {pillar.title}
                </h3>
                <p className="mt-4 text-base leading-7 text-gray-600">
                  {pillar.description}
                </p>
                
                <ul className="mt-8 space-y-4">
                  {pillar.practices.map((practice) => (
                    <li
                      key={practice}
                      className="flex items-center text-sm text-gray-600"
                    >
                      <span className="mr-3 h-2 w-2 flex-none rounded-full bg-blue-600" />
                      {practice}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>

        <div className="mt-16 flex justify-center">
          <Link to="/careers">
            <Button
              size="lg"
              className="gap-2 transition-transform hover:-translate-y-0.5"
            >
              Join Our Family
              <ArrowRight className="h-5 w-5" />
            </Button>
          </Link>
        </div>
      </div>
    </section>
  );
}