import TeamHero from '../../components/sections/team/TeamHero';
import TeamStats from '../../components/sections/team/TeamStats';
import TeamProfiles from '../../components/sections/team/TeamProfiles';
import TeamCTA from '../../components/sections/team/TeamCTA';

export default function LeadershipPage() {
  return (
    <main className="min-h-screen pt-16">
      <TeamHero />
      <TeamStats />
      <TeamProfiles />
      <TeamCTA />
    </main>
  );
}