import { 
  Heart, 
  LifeBuoy, 
  Stethoscope, 
  ShieldCheck, 
  PiggyBank,
  CheckCircle2,
  ArrowRight,
} from 'lucide-react';
import { Link } from 'react-router-dom';
import Button from '../ui/Button';

const services = [
  {
    name: 'Final Expense Insurance',
    description: 'Simplified underwriting and quick approvals for peace of mind.',
    icon: Heart,
    link: '/contact',
    benefits: [
      'Guaranteed issue options available',
      'Same-day approvals',
      'Coverage up to $50,000'
    ]
  },
  {
    name: 'Life Insurance',
    description: 'Comprehensive coverage options for families and individuals.',
    icon: ShieldCheck,
    link: '/contact',
    benefits: [
      'Term and permanent options',
      'Competitive rates',
      'Living benefits'
    ]
  },
  {
    name: 'Medicare',
    description: 'Expert guidance on Medicare plans and enrollment.',
    icon: Stethoscope,
    link: '/contact',
    benefits: [
      'Medicare Advantage plans',
      'Prescription Drug coverage',
      'Supplement plans'
    ]
  },
  {
    name: 'ACA Health Plans',
    description: 'Navigate and secure affordable healthcare coverage.',
    icon: LifeBuoy,
    link: '/contact',
    benefits: [
      'Subsidy assistance',
      'Year-round enrollment',
      'Network flexibility'
    ]
  },
  {
    name: 'Annuities',
    description: 'Secure retirement solutions with competitive rates.',
    icon: PiggyBank,
    link: '/contact',
    benefits: [
      'Fixed and indexed options',
      'Guaranteed income',
      'Tax-deferred growth'
    ]
  }
];

export default function Highlights() {
  return (
    <section id="solutions" className="relative bg-white py-24 sm:py-32">
      <div className="absolute inset-0 bg-gradient-to-b from-blue-50/50 to-white" />
      <div className="relative mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Comprehensive Insurance Solutions
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            UIP provides agents with industry-leading products, technology, and support
            to help grow their business and serve their clients better.
          </p>
        </div>

        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 lg:max-w-none lg:grid-cols-2 xl:gap-x-12">
          {services.map((service, serviceIdx) => (
            <div
              key={service.name}
              className={`group relative overflow-hidden rounded-2xl bg-white p-8 shadow-md ring-1 ring-gray-200 transition-all hover:shadow-lg ${
                serviceIdx === services.length - 1 ? 'lg:col-span-2 xl:col-span-1' : ''
              }`}
            >
              <div className="absolute inset-0 bg-gradient-to-br from-blue-50/50 via-white to-white opacity-0 transition-opacity group-hover:opacity-100" />
              
              <div className="relative">
                <div className="flex items-center gap-4">
                  <div className="rounded-lg bg-blue-50 p-2 text-blue-600">
                    <service.icon className="h-6 w-6" />
                  </div>
                  <h3 className="text-xl font-semibold leading-7 text-gray-900">
                    {service.name}
                  </h3>
                </div>

                <p className="mt-4 text-base leading-7 text-gray-600">
                  {service.description}
                </p>

                <ul className="mt-8 space-y-3">
                  {service.benefits.map((benefit) => (
                    <li key={benefit} className="flex items-center gap-3">
                      <CheckCircle2 className="h-5 w-5 flex-none text-blue-500" />
                      <span className="text-sm text-gray-600">{benefit}</span>
                    </li>
                  ))}
                </ul>

                <div className="mt-8 flex items-center gap-4">
                  <Link to={service.link}>
                    <Button
                      variant="outline"
                      size="sm"
                      className="group/btn transition-transform hover:-translate-y-0.5"
                    >
                      Learn More
                      <span className="ml-2 transition-transform group-hover/btn:translate-x-0.5">
                        →
                      </span>
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="mx-auto mt-16 flex max-w-2xl flex-col items-center">
          <div className="rounded-full bg-blue-50 px-4 py-1.5 text-sm font-medium text-blue-600">
            Ready to get started?
          </div>
          <h3 className="mt-4 text-center text-2xl font-bold tracking-tight text-gray-900">
            Join UIP Today and Grow Your Business
          </h3>
          <p className="mt-4 text-center text-gray-600">
            Access our comprehensive suite of products and start providing better solutions for your clients.
          </p>
          <Link to="/contact">
            <Button size="lg" className="mt-8 gap-2 transition-transform hover:-translate-y-0.5">
              Get Started
              <ArrowRight className="h-5 w-5" />
            </Button>
          </Link>
        </div>
      </div>
    </section>
  );
}