import { ShieldOff, LogIn, ArrowLeft } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../../components/ui/Button';

export default function AccessDeniedPage() {
  const navigate = useNavigate();

  return (
    <main className="grid min-h-screen place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <div className="flex justify-center">
          <ShieldOff className="h-16 w-16 text-red-600" />
        </div>
        
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          Access Denied
        </h1>
        
        <p className="mt-6 text-base leading-7 text-gray-600">
          You don't have permission to access this page. Please log in with an
          authorized account or contact support for assistance.
        </p>

        {/* Error Details */}
        <div className="mx-auto mt-10 max-w-xl rounded-xl bg-red-50 p-8">
          <h2 className="text-sm font-medium text-red-800">
            Why am I seeing this?
          </h2>
          <ul className="mt-4 list-disc space-y-2 pl-5 text-sm text-red-700">
            <li>Your session may have expired</li>
            <li>You may need additional permissions</li>
            <li>You might be using an unauthorized account</li>
          </ul>
        </div>

        {/* Action Buttons */}
        <div className="mt-10 flex items-center justify-center gap-6">
          <Button
            onClick={() => navigate(-1)}
            variant="outline"
            className="gap-2"
          >
            <ArrowLeft className="h-4 w-4" />
            Go Back
          </Button>
          
          <Link to="/login">
            <Button className="gap-2">
              <LogIn className="h-4 w-4" />
              Log In
            </Button>
          </Link>
        </div>

        {/* Support Contact */}
        <p className="mt-10 text-sm text-gray-600">
          Need help? Contact our support team at{' '}
          <a
            href="mailto:support@uipinsurance.com"
            className="font-medium text-blue-600 hover:text-blue-500"
          >
            support@uipinsurance.com
          </a>
        </p>
      </div>
    </main>
  );
}