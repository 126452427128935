import { Link } from 'react-router-dom';
import { cn } from '../../lib/utils';

interface LogoProps {
  className?: string;
  size?: 'sm' | 'md' | 'lg';
}

export default function Logo({ className = '', size = 'md' }: LogoProps) {
  return (
    <Link to="/" className={cn('block', className)}>
      <img
        src="/assets/UIP-Logo.png"
        alt="United Insurance Professionals"
        className={cn(
          'w-auto object-contain',
          size === 'sm' && 'h-12',
          size === 'md' && 'h-16',
          size === 'lg' && 'h-20'
        )}
      />
    </Link>
  );
}
