import { Link } from 'react-router-dom';
import { MAIN_NAV } from '../../constants/navigation';
import NavDropdown from './NavDropdown';
import { cn } from '../../lib/utils';

export default function MainNav() {
  return (
    <nav 
      className="mx-auto flex max-w-7xl items-center gap-x-6 px-6 lg:px-8" 
      aria-label="Main navigation"
    >
      {MAIN_NAV.map((item) => (
        <div key={item.name} className="relative">
          {item.items ? (
            <NavDropdown item={item} />
          ) : (
            <Link
              to={item.href}
              className={cn(`
                text-sm font-semibold leading-6 text-gray-900 whitespace-nowrap
                outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2
                transition-colors duration-200 hover:text-blue-600
                relative after:absolute after:bottom-0 after:left-0 after:h-0.5
                after:w-0 after:bg-blue-600 after:transition-all after:duration-200
                hover:after:w-full
              `,
              item.href === '/' && 'pl-0'
              )}
            >
              {item.name}
            </Link>
          )}
        </div>
      ))}
    </nav>
  );
}