import { Clock, Users, Award, HeadphonesIcon } from 'lucide-react';

const stats = [
  {
    label: 'Combined Experience',
    value: '100+',
    unit: 'Years',
    icon: Clock,
  },
  {
    label: 'Families Protected',
    value: '15,000+',
    unit: 'Clients',
    icon: Users,
  },
  {
    label: 'Client Satisfaction',
    value: '98',
    unit: '%',
    icon: Award,
  },
  {
    label: 'Agent Support',
    value: '24/7',
    unit: 'Availability',
    icon: HeadphonesIcon,
  },
];

export default function TeamStats() {
  return (
    <section className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-8 text-center sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-4">
          {stats.map((stat) => (
            <div
              key={stat.label}
              className="group flex flex-col items-center gap-3 rounded-2xl bg-gray-50 p-8 transition-all hover:bg-blue-50"
            >
              <div className="rounded-lg bg-white p-3 shadow-sm ring-1 ring-gray-200/50 transition-colors group-hover:bg-blue-600 group-hover:text-white">
                <stat.icon className="h-6 w-6" />
              </div>
              <div>
                <div className="flex items-baseline justify-center gap-2">
                  <span className="text-3xl font-semibold tracking-tight text-gray-900">
                    {stat.value}
                  </span>
                  <span className="text-sm text-gray-600">{stat.unit}</span>
                </div>
                <p className="mt-1 text-sm font-medium text-gray-600">
                  {stat.label}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}