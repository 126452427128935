import { Shield, Star } from 'lucide-react';

interface CarrierCardProps {
  carrier: {
    name: string;
    logo: string;
    rating: string;
    specialties: string[];
    partnerLevel: string;
    features: string[];
  };
}

export default function CarrierCard({ carrier }: CarrierCardProps) {
  return (
    <div className="group relative rounded-2xl bg-white p-8 shadow-lg ring-1 ring-gray-200/50 transition-all hover:shadow-xl">
      <div className="absolute inset-0 bg-gradient-to-br from-blue-50/50 via-white to-white opacity-0 transition-opacity group-hover:opacity-100" />
      
      <div className="relative">
        {/* Logo & Rating */}
        <div className="flex items-center justify-between">
          <img
            src={carrier.logo}
            alt={carrier.name}
            className="h-12 w-auto object-contain"
          />
          <div className="flex items-center gap-1 rounded-full bg-blue-50 px-3 py-1">
            <Shield className="h-4 w-4 text-blue-600" />
            <span className="text-sm font-medium text-blue-600">
              {carrier.rating}
            </span>
          </div>
        </div>

        {/* Carrier Info */}
        <div className="mt-6">
          <h3 className="text-xl font-semibold text-gray-900">{carrier.name}</h3>
          <div className="mt-2 flex items-center gap-2">
            <Star className="h-4 w-4 text-amber-400" />
            <span className="text-sm font-medium text-gray-600">
              {carrier.partnerLevel}
            </span>
          </div>
        </div>

        {/* Specialties */}
        <div className="mt-6">
          <h4 className="text-sm font-medium text-gray-900">Specialties</h4>
          <div className="mt-2 flex flex-wrap gap-2">
            {carrier.specialties.map((specialty) => (
              <span
                key={specialty}
                className="rounded-full bg-blue-50 px-3 py-1 text-sm text-blue-600"
              >
                {specialty}
              </span>
            ))}
          </div>
        </div>

        {/* Features */}
        <div className="mt-6">
          <h4 className="text-sm font-medium text-gray-900">Features</h4>
          <ul className="mt-4 space-y-3">
            {carrier.features.map((feature) => (
              <li
                key={feature}
                className="flex items-center text-sm text-gray-600"
              >
                <span className="mr-3 h-1.5 w-1.5 flex-none rounded-full bg-blue-600" />
                {feature}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}