import { Shield, Heart, Stethoscope, PiggyBank } from 'lucide-react';

const productCategories = [
  {
    name: 'Life Insurance',
    description: 'Comprehensive life insurance solutions',
    icon: Shield,
    products: [
      { name: 'Term Life Insurance', carriers: ['Pinnacle', 'Guardian', 'Liberty'] },
      { name: 'Whole Life Insurance', carriers: ['Pinnacle', 'Guardian'] },
      { name: 'Universal Life', carriers: ['Pinnacle', 'Liberty'] },
      { name: 'Final Expense', carriers: ['Guardian', 'Liberty'] },
    ]
  },
  {
    name: 'Health Insurance',
    description: 'Individual and family health coverage',
    icon: Heart,
    products: [
      { name: 'Individual Plans', carriers: ['Liberty', 'Guardian'] },
      { name: 'Family Coverage', carriers: ['Liberty', 'Pinnacle'] },
      { name: 'Short-term Medical', carriers: ['Guardian'] },
      { name: 'Supplemental Health', carriers: ['Pinnacle', 'Liberty'] },
    ]
  },
  {
    name: 'Medicare Solutions',
    description: 'Complete Medicare coverage options',
    icon: Stethoscope,
    products: [
      { name: 'Medicare Advantage', carriers: ['Liberty', 'Pinnacle'] },
      { name: 'Medicare Supplements', carriers: ['Liberty', 'Guardian'] },
      { name: 'Prescription Drug Plans', carriers: ['Liberty'] },
      { name: 'Special Needs Plans', carriers: ['Liberty', 'Pinnacle'] },
    ]
  },
  {
    name: 'Retirement Solutions',
    description: 'Long-term financial security products',
    icon: PiggyBank,
    products: [
      { name: 'Fixed Annuities', carriers: ['Pinnacle', 'Guardian'] },
      { name: 'Indexed Annuities', carriers: ['Pinnacle'] },
      { name: 'Income Annuities', carriers: ['Guardian', 'Liberty'] },
      { name: 'Long-term Care', carriers: ['Pinnacle', 'Guardian'] },
    ]
  }
];

export default function CarrierProductsPage() {
  return (
    <main className="min-h-screen bg-gray-50 pt-16">
      <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8">
        {/* Page Header */}
        <div className="text-center">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900">
            Product Categories
          </h1>
          <p className="mx-auto mt-6 max-w-2xl text-lg text-gray-600">
            Explore our comprehensive range of insurance products across multiple
            carriers to find the perfect solution for your clients.
          </p>
        </div>

        {/* Product Categories Grid */}
        <div className="mt-16 grid gap-8 md:grid-cols-2">
          {productCategories.map((category) => (
            <div
              key={category.name}
              className="group relative overflow-hidden rounded-2xl bg-white p-8 shadow-lg ring-1 ring-gray-200/50 transition-all hover:shadow-xl"
            >
              <div className="absolute inset-0 bg-gradient-to-br from-blue-50/50 via-white to-white opacity-0 transition-opacity group-hover:opacity-100" />
              
              <div className="relative">
                {/* Category Header */}
                <div className="flex items-center gap-4">
                  <div className="rounded-lg bg-blue-50 p-2 text-blue-600">
                    <category.icon className="h-6 w-6" />
                  </div>
                  <div>
                    <h2 className="text-xl font-semibold text-gray-900">
                      {category.name}
                    </h2>
                    <p className="text-sm text-gray-600">{category.description}</p>
                  </div>
                </div>

                {/* Products Table */}
                <div className="mt-8">
                  <div className="overflow-hidden rounded-lg border border-gray-200">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th className="px-4 py-2 text-left text-xs font-medium text-gray-500">
                            Product
                          </th>
                          <th className="px-4 py-2 text-left text-xs font-medium text-gray-500">
                            Available Carriers
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200">
                        {category.products.map((product) => (
                          <tr key={product.name}>
                            <td className="whitespace-nowrap px-4 py-2 text-sm text-gray-900">
                              {product.name}
                            </td>
                            <td className="px-4 py-2">
                              <div className="flex flex-wrap gap-2">
                                {product.carriers.map((carrier) => (
                                  <span
                                    key={carrier}
                                    className="rounded-full bg-blue-50 px-2 py-1 text-xs font-medium text-blue-600"
                                  >
                                    {carrier}
                                  </span>
                                ))}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </main>
  );
}