import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

const classNameCache = new WeakMap<ClassValue[], string>();

/**
 * Merge Tailwind CSS classes with clsx and tailwind-merge
 */
export function cn(...inputs: ClassValue[]) {
  if (classNameCache.has(inputs)) {
    return classNameCache.get(inputs)!;
  }
  const result = twMerge(clsx(inputs));
  classNameCache.set(inputs, result);
  return result;
}

/**
 * Image loading optimization helper
 */
export function getImageProps(src: string, sizes: string) {
  return {
    src,
    loading: 'lazy' as const,
    decoding: 'async' as const,
    sizes,
  };
}

/**
 * Mobile detection utility
 */
export function isMobileDevice() {
  return (
    typeof window !== 'undefined' &&
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      window.navigator.userAgent
    )
  );
}

/**
 * Format a date using Intl.DateTimeFormat
 */
export function formatDate(date: Date): string {
  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }).format(date);
}

/**
 * Validate email address format
 */
export function isValidEmail(email: string): boolean {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
}

/**
 * Create a debounced function that delays invoking func until after wait milliseconds
 */
export function debounce<T extends (...args: any[]) => any>(
  func: T,
  wait: number
): (...args: Parameters<T>) => void {
  let timeoutId: ReturnType<typeof setTimeout>;

  return function (...args: Parameters<T>) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), wait);
  };
}

/**
 * Lazy load images with IntersectionObserver
 */
export function lazyLoadImage(imageUrl: string): Promise<string> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = imageUrl;
    img.onload = () => resolve(imageUrl);
    img.onerror = () => reject(new Error(`Failed to load image: ${imageUrl}`));
  });
}

/**
 * Create a memoized version of a function
 */
export function memoize<T extends (...args: any[]) => any>(
  fn: T,
  keyGenerator?: (...args: Parameters<T>) => string
): T {
  const cache = new Map<string, ReturnType<T>>();

  return ((...args: Parameters<T>): ReturnType<T> => {
    const key = keyGenerator ? keyGenerator(...args) : JSON.stringify(args);
    if (cache.has(key)) {
      return cache.get(key)!;
    }
    const result = fn(...args);
    cache.set(key, result);
    return result;
  }) as T;
}

/**
 * Generate a unique key for list items
 */
export const generateKey = memoize((prefix: string, ...values: (string | number)[]): string => {
  const uniqueString = values.join('-');
  const hash = uniqueString.split('').reduce((acc, char) => {
    return (acc << 5) - acc + char.charCodeAt(0) | 0;
  }, 0);
  return `${prefix}-${Math.abs(hash)}`;
});

/**
 * Format file size
 */
export function formatFileSize(bytes: number): string {
  const units = ['B', 'KB', 'MB', 'GB'];
  let size = bytes;
  let unitIndex = 0;

  while (size >= 1024 && unitIndex < units.length - 1) {
    size /= 1024;
    unitIndex++;
  }

  return `${Math.round(size * 100) / 100}${units[unitIndex]}`;
}