import { ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import Button from '../../ui/Button';

export default function TeamCTA() {
  return (
    <section className="relative isolate overflow-hidden bg-blue-600 py-24 sm:py-32">
      <div className="absolute inset-0 -z-10 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.blue.500),theme(colors.blue.800))]" />
      
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
            Join Our Growing Team
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-100">
            Be part of a dynamic team that's transforming the insurance industry.
            We're always looking for talented professionals to join our family.
          </p>
          <div className="mt-10 flex flex-col items-center gap-6 sm:flex-row sm:justify-center">
            <Link to="/careers">
              <Button
                size="lg"
                className="w-full gap-2 bg-white text-blue-600 hover:bg-gray-100 sm:w-auto"
              >
                View Open Positions
                <ArrowRight className="h-5 w-5" />
              </Button>
            </Link>
            <Link to="/contact">
              <Button
                size="lg"
                variant="outline"
                className="w-full border-white text-white hover:bg-white/10 sm:w-auto"
              >
                Contact Us
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}