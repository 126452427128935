import { analytics } from './firebase/config';
import { logEvent, type AnalyticsCallOptions } from 'firebase/analytics';

type EventParams = {
  [key: string]: string | number | boolean | undefined;
};

export function trackPageView(path: string, search: string) {
  // Silently track in development
  if (process.env.NODE_ENV === 'development') return;

  try {
    logEvent(analytics, 'page_view', {
      page_path: path,
      page_search: search || '',
      page_location: window.location.href,
      page_title: document.title
    });
  } catch (error) {
    console.error('Error tracking page view:', error);
  }
}

export function trackEvent(
  eventName: string,
  eventParams?: EventParams,
  options?: AnalyticsCallOptions
) {
  // Silently track in development
  if (process.env.NODE_ENV === 'development') return;

  try {
    logEvent(analytics, eventName, eventParams, options);
  } catch (error) {
    console.error('Error tracking event:', error);
  }
}
