import { Star, Award, TrendingUp, Users } from 'lucide-react';
import Button from '../../components/ui/Button';

const partnerLevels = [
  {
    name: 'Elite Partner',
    description: 'Top-tier partnership with exclusive benefits',
    icon: Star,
    requirements: [
      'Annual premium: $2M+',
      'Persistency rate: 85%+',
      'Quality score: 90%+',
      'Active agent count: 50+'
    ],
    benefits: [
      'Highest commission rates',
      'Executive support team',
      'Custom marketing budget',
      'Priority underwriting'
    ]
  },
  {
    name: 'Premier Partner',
    description: 'Enhanced partnership level with advanced support',
    icon: Award,
    requirements: [
      'Annual premium: $1M+',
      'Persistency rate: 80%+',
      'Quality score: 85%+',
      'Active agent count: 25+'
    ],
    benefits: [
      'Enhanced commission rates',
      'Dedicated support team',
      'Marketing allowance',
      'Fast-track underwriting'
    ]
  },
  {
    name: 'Growth Partner',
    description: 'Entry-level partnership with growth support',
    icon: TrendingUp,
    requirements: [
      'Annual premium: $250K+',
      'Persistency rate: 75%+',
      'Quality score: 80%+',
      'Active agent count: 10+'
    ],
    benefits: [
      'Competitive commission rates',
      'Standard support access',
      'Basic marketing tools',
      'Standard underwriting'
    ]
  }
];

export default function PartnerProgramsPage() {
  return (
    <main className="min-h-screen bg-gray-50 pt-16">
      <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8">
        {/* Page Header */}
        <div className="text-center">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900">
            Partner Programs
          </h1>
          <p className="mx-auto mt-6 max-w-2xl text-lg text-gray-600">
            Join our partnership program and unlock exclusive benefits, enhanced
            support, and competitive advantages for your business.
          </p>
        </div>

        {/* Partner Levels Grid */}
        <div className="mt-16 grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          {partnerLevels.map((level) => (
            <div
              key={level.name}
              className="group relative overflow-hidden rounded-2xl bg-white p-8 shadow-lg ring-1 ring-gray-200/50 transition-all hover:shadow-xl"
            >
              <div className="absolute inset-0 bg-gradient-to-br from-blue-50/50 via-white to-white opacity-0 transition-opacity group-hover:opacity-100" />
              
              <div className="relative">
                <div className="flex items-center gap-4">
                  <div className="rounded-lg bg-blue-50 p-2 text-blue-600">
                    <level.icon className="h-6 w-6" />
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold text-gray-900">
                      {level.name}
                    </h3>
                    <p className="text-sm text-gray-600">{level.description}</p>
                  </div>
                </div>

                {/* Requirements */}
                <div className="mt-8">
                  <h4 className="text-sm font-medium text-gray-900">
                    Requirements
                  </h4>
                  <ul className="mt-4 space-y-3">
                    {level.requirements.map((req) => (
                      <li
                        key={req}
                        className="flex items-center text-sm text-gray-600"
                      >
                        <span className="mr-3 h-1.5 w-1.5 flex-none rounded-full bg-blue-600" />
                        {req}
                      </li>
                    ))}
                  </ul>
                </div>

                {/* Benefits */}
                <div className="mt-8">
                  <h4 className="text-sm font-medium text-gray-900">
                    Benefits
                  </h4>
                  <ul className="mt-4 space-y-3">
                    {level.benefits.map((benefit) => (
                      <li
                        key={benefit}
                        className="flex items-center text-sm text-gray-600"
                      >
                        <span className="mr-3 h-1.5 w-1.5 flex-none rounded-full bg-green-600" />
                        {benefit}
                      </li>
                    ))}
                  </ul>
                </div>

                {/* Action Button */}
                <div className="mt-8">
                  <Button
                    variant="outline"
                    className="w-full justify-center transition-transform hover:-translate-y-0.5"
                  >
                    Apply Now
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Additional Information */}
        <div className="mt-16 rounded-2xl bg-blue-50 p-8">
          <div className="flex items-center gap-4">
            <div className="rounded-lg bg-blue-600 p-2 text-white">
              <Users className="h-6 w-6" />
            </div>
            <h2 className="text-xl font-semibold text-gray-900">
              Ready to Partner?
            </h2>
          </div>
          <p className="mt-4 text-gray-600">
            Contact our partnership team to learn more about our programs and find
            the right level for your business.
          </p>
          <div className="mt-6">
            <Button>Contact Partnership Team</Button>
          </div>
        </div>
      </div>
    </main>
  );
}