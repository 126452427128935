import { DollarSign, TrendingUp, Award } from 'lucide-react';

const commissionLevels = [
  {
    name: 'Standard Level',
    description: 'Base commission structure for new agents',
    icon: DollarSign,
    rates: [
      { product: 'Term Life', rate: '80%' },
      { product: 'Whole Life', rate: '85%' },
      { product: 'Medicare Advantage', rate: '$400-600' },
      { product: 'Medicare Supplements', rate: '20%' }
    ]
  },
  {
    name: 'Premier Level',
    description: 'Enhanced rates for consistent producers',
    icon: TrendingUp,
    rates: [
      { product: 'Term Life', rate: '90%' },
      { product: 'Whole Life', rate: '95%' },
      { product: 'Medicare Advantage', rate: '$500-700' },
      { product: 'Medicare Supplements', rate: '22%' }
    ]
  },
  {
    name: 'Elite Level',
    description: 'Top-tier compensation for high performers',
    icon: Award,
    rates: [
      { product: 'Term Life', rate: '100%' },
      { product: 'Whole Life', rate: '105%' },
      { product: 'Medicare Advantage', rate: '$600-800' },
      { product: 'Medicare Supplements', rate: '25%' }
    ]
  }
];

export default function CommissionsPage() {
  return (
    <main className="min-h-screen bg-gray-50 pt-16">
      <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8">
        {/* Page Header */}
        <div className="text-center">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900">
            Commission Structure
          </h1>
          <p className="mx-auto mt-6 max-w-2xl text-lg text-gray-600">
            Competitive compensation plans designed to reward your success and
            growth with our organization.
          </p>
        </div>

        {/* Commission Levels Grid */}
        <div className="mt-16 grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          {commissionLevels.map((level) => (
            <div
              key={level.name}
              className="group relative overflow-hidden rounded-2xl bg-white p-8 shadow-lg ring-1 ring-gray-200/50 transition-all hover:shadow-xl"
            >
              <div className="absolute inset-0 bg-gradient-to-br from-blue-50/50 via-white to-white opacity-0 transition-opacity group-hover:opacity-100" />
              
              <div className="relative">
                <div className="flex items-center gap-4">
                  <div className="rounded-lg bg-blue-50 p-2 text-blue-600">
                    <level.icon className="h-6 w-6" />
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold text-gray-900">
                      {level.name}
                    </h3>
                    <p className="text-sm text-gray-600">{level.description}</p>
                  </div>
                </div>

                <div className="mt-8">
                  <h4 className="text-sm font-medium text-gray-900">
                    Commission Rates
                  </h4>
                  <div className="mt-4 overflow-hidden rounded-lg border border-gray-200">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th className="px-4 py-2 text-left text-xs font-medium text-gray-500">
                            Product
                          </th>
                          <th className="px-4 py-2 text-right text-xs font-medium text-gray-500">
                            Rate
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200">
                        {level.rates.map((rate) => (
                          <tr key={rate.product}>
                            <td className="whitespace-nowrap px-4 py-2 text-sm text-gray-900">
                              {rate.product}
                            </td>
                            <td className="whitespace-nowrap px-4 py-2 text-right text-sm text-gray-900">
                              {rate.rate}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Additional Information */}
        <div className="mt-16 rounded-2xl bg-blue-50 p-8">
          <h2 className="text-xl font-semibold text-gray-900">
            Important Information
          </h2>
          <ul className="mt-4 space-y-3">
            <li className="flex items-center text-sm text-gray-600">
              <span className="mr-3 h-1.5 w-1.5 flex-none rounded-full bg-blue-600" />
              Commission rates are subject to carrier guidelines and state regulations
            </li>
            <li className="flex items-center text-sm text-gray-600">
              <span className="mr-3 h-1.5 w-1.5 flex-none rounded-full bg-blue-600" />
              Level advancement is based on rolling 12-month production
            </li>
            <li className="flex items-center text-sm text-gray-600">
              <span className="mr-3 h-1.5 w-1.5 flex-none rounded-full bg-blue-600" />
              Additional bonuses and incentives may be available
            </li>
          </ul>
        </div>
      </div>
    </main>
  );
}