import { Link } from 'react-router-dom';
import Button from '../ui/Button';

const carriers = [
  {
    name: 'Insurance Provider 1',
    logo: 'https://images.unsplash.com/photo-1560179707-f14e90ef3623?w=200&h=100&fit=crop&auto=format',
  },
  {
    name: 'Insurance Provider 2',
    logo: 'https://images.unsplash.com/photo-1560179707-f14e90ef3623?w=200&h=100&fit=crop&auto=format',
  },
  {
    name: 'Insurance Provider 3',
    logo: 'https://images.unsplash.com/photo-1560179707-f14e90ef3623?w=200&h=100&fit=crop&auto=format',
  },
  {
    name: 'Insurance Provider 4',
    logo: 'https://images.unsplash.com/photo-1560179707-f14e90ef3623?w=200&h=100&fit=crop&auto=format',
  },
  {
    name: 'Insurance Provider 5',
    logo: 'https://images.unsplash.com/photo-1560179707-f14e90ef3623?w=200&h=100&fit=crop&auto=format',
  },
  {
    name: 'Insurance Provider 6',
    logo: 'https://images.unsplash.com/photo-1560179707-f14e90ef3623?w=200&h=100&fit=crop&auto=format',
  },
];

export default function Carriers() {
  return (
    <section id="carriers" className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Our Trusted Carriers
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Partner with industry-leading insurance carriers offering competitive 
            products and reliable coverage for your clients.
          </p>
        </div>
        <div className="mx-auto mt-16 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-12 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 sm:gap-y-14 lg:mx-0 lg:max-w-none">
          {carriers.map((carrier) => (
            <img
              key={carrier.name}
              className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
              src={carrier.logo}
              alt={carrier.name}
              width={158}
              height={48}
            />
          ))}
        </div>
        <div className="mt-16 flex justify-center">
          <Link to="/contact">
            <Button
              variant="outline"
              size="lg"
              className="transition-transform hover:-translate-y-0.5"
            >
              Become a Partner
            </Button>
          </Link>
        </div>
      </div>
    </section>
  );
}