import { type LucideIcon, Home, Users, Briefcase, Phone, FileText, GraduationCap, Heart, ShieldCheck, Headphones, Building2, Settings } from 'lucide-react';

export interface NavItem {
  readonly name: string;
  readonly href: string;
  readonly icon?: LucideIcon;
  readonly items?: readonly SubNavItem[];
}

export interface SubNavItem {
  readonly name: string;
  readonly href: string;
  readonly description?: string;
}

export const MAIN_NAV: readonly NavItem[] = [
  { name: 'Home', href: '/', icon: Home },
  {
    name: 'Insurance Solutions',
    href: '/insurance',
    icon: ShieldCheck,
    items: [
      { 
        name: 'Life Insurance',
        href: '/insurance/life',
        description: 'Term, Whole, Universal & Final Expense coverage'
      },
      {
        name: 'Health Insurance',
        href: '/insurance/health',
        description: 'Individual and Family Health Plans'
      },
      {
        name: 'Medicare Solutions',
        href: '/insurance/medicare',
        description: 'Medicare Advantage & Supplement Plans'
      },
      {
        name: 'Annuity Solutions',
        href: '/insurance/annuities',
        description: 'Fixed, Indexed & Multi-Year Guarantee Annuities'
      }
    ]
  },
  {
    name: 'Agent Resources',
    href: '/resources',
    icon: FileText,
    items: [
      {
        name: 'Training & Development',
        href: '/resources/training',
        description: 'Professional growth resources'
      },
      {
        name: 'Document Center',
        href: '/resources/documents',
        description: 'Forms, guides, and materials'
      },
      {
        name: 'Marketing Resources',
        href: '/resources/marketing',
        description: 'Tools and templates'
      },
      {
        name: 'Commission Structure',
        href: '/resources/commissions',
        description: 'Compensation information'
      }
    ]
  },
  {
    name: 'Carrier Network',
    href: '/carriers',
    icon: Building2,
    items: [
      { name: 'Carrier Directory', href: '/carriers/directory', description: 'Our insurance partners' },
      { name: 'Product Categories', href: '/carriers/products', description: 'Available product lines' },
      { name: 'Partner Programs', href: '/carriers/programs', description: 'Partnership opportunities' }
    ]
  },
  {
    name: 'About Us',
    href: '/about',
    icon: Users,
    items: [
      { name: 'Our Story', href: '/about/story', description: 'The history and evolution of UIP' },
      { name: 'Leadership Team', href: '/about/leadership', description: 'Meet our executive team' },
      { name: 'Company Culture', href: '/about/culture', description: 'What it\'s like to work with us' },
      { name: 'News & Updates', href: '/about/news', description: 'Latest company news' }
    ]
  },
  { name: 'Contact', href: '/contact', icon: Phone }
];