import Hero from '../../components/sections/story/Hero';
import Mission from '../../components/sections/story/Mission';
import Timeline from '../../components/sections/story/Timeline';

export default function StoryPage() {
  return (
    <main className="min-h-screen pt-16">
      <Hero />
      <Mission />
      <Timeline />
    </main>
  );
}