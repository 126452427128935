import { useState } from 'react';
import { Shield, Heart, PiggyBank } from 'lucide-react';
import PlanCard from '../../components/insurance/shared/PlanCard';
import ResourceCenter from '../../components/insurance/shared/ResourceCenter';

const plans = [
  {
    name: 'Medicare Advantage',
    description: 'All-in-one Medicare coverage',
    icon: Shield,
    features: [
      'Hospital coverage (Part A)',
      'Medical coverage (Part B)',
      'Prescription drugs (Part D)',
      'Additional benefits'
    ],
    benefits: [
      'Low or $0 monthly premiums',
      'Extra benefits included',
      'One card simplicity',
      'Maximum out-of-pocket protection'
    ]
  },
  {
    name: 'Medicare Supplements',
    description: 'Fill the gaps in Original Medicare',
    icon: Heart,
    features: [
      'Standardized benefits',
      'Guaranteed renewable',
      'No network restrictions',
      'Nationwide coverage'
    ],
    benefits: [
      'Predictable costs',
      'Choose any doctor',
      'No referrals needed',
      'Travel coverage included'
    ]
  },
  {
    name: 'Prescription Drug Plans',
    description: 'Medicare Part D coverage',
    icon: PiggyBank,
    features: [
      'Comprehensive drug coverage',
      'Pharmacy network access',
      'Mail order options',
      'Coverage gap protection'
    ],
    benefits: [
      'Affordable drug costs',
      'Convenient pharmacy access',
      'Save with mail order',
      'Coverage in the gap'
    ]
  }
];

export default function MedicarePage() {
  const [activeTab, setActiveTab] = useState('plans');

  return (
    <main className="min-h-screen bg-gray-50 pt-16">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        {/* Page Header */}
        <div className="py-16 lg:py-24">
          <div className="text-center">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              Medicare Solutions
            </h1>
            <p className="mx-auto mt-6 max-w-2xl text-lg text-gray-600">
              Expert guidance and comprehensive Medicare coverage options to help
              seniors make informed healthcare decisions.
            </p>
          </div>
        </div>

        {/* Navigation Tabs */}
        <nav className="mb-8 flex justify-center space-x-4" aria-label="Medicare Navigation">
          <button
            onClick={() => setActiveTab('plans')}
            className={`rounded-lg px-4 py-2 text-sm font-medium transition-colors
              ${activeTab === 'plans' 
                ? 'bg-blue-600 text-white' 
                : 'bg-white text-gray-700 hover:bg-gray-100'}`}
          >
            Medicare Plans
          </button>
          <button
            onClick={() => setActiveTab('enrollment')}
            className={`rounded-lg px-4 py-2 text-sm font-medium transition-colors
              ${activeTab === 'enrollment' 
                ? 'bg-blue-600 text-white' 
                : 'bg-white text-gray-700 hover:bg-gray-100'}`}
          >
            Enrollment Periods
          </button>
        </nav>

        {/* Plans Grid */}
        <div className="mb-16 grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          {plans.map((plan) => (
            <PlanCard key={plan.name} plan={plan} />
          ))}
        </div>

        {/* Resource Center */}
        <ResourceCenter productType="medicare" />
      </div>
    </main>
  );
}