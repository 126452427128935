import Hero from '../components/sections/Hero';
import Highlights from '../components/sections/Highlights';
import AgentBenefits from '../components/sections/AgentBenefits';
import Testimonials from '../components/sections/Testimonials';
import Carriers from '../components/sections/Carriers';

export default function HomePage() {
  return (
    <main className="min-h-screen pt-28 lg:pt-0">
      <Hero />
      <Highlights />
      <AgentBenefits />
      <Carriers />
      <Testimonials />
    </main>
  );
}
