import { Star } from 'lucide-react';

const testimonials = [
  {
    content: "Joining UIP was the best decision for my insurance career. Their training and support have helped me double my business in just six months.",
    author: "Jennifer Martinez",
    role: "Independent Agent",
    image: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=100&h=100&fit=crop&auto=format",
    rating: 5
  },
  {
    content: "The technology and marketing resources UIP provides are game-changers. I can focus on serving clients while their systems handle the rest.",
    author: "Michael Chang",
    role: "Senior Insurance Advisor",
    image: "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=100&h=100&fit=crop&auto=format",
    rating: 5
  },
  {
    content: "UIP's lead program and CRM tools have transformed my practice. I've seen a 200% increase in my Medicare sales since joining.",
    author: "Sarah Williams",
    role: "Medicare Specialist",
    image: "https://images.unsplash.com/photo-1580489944761-15a19d654956?w=100&h=100&fit=crop&auto=format",
    rating: 5
  }
];

export default function Testimonials() {
  return (
    <section className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="absolute inset-0 -z-10 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.blue.100),white)]" />
      <div className="absolute inset-y-0 right-1/2 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] bg-white shadow-xl shadow-blue-600/10 ring-1 ring-blue-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />
      <div className="mx-auto max-w-2xl lg:max-w-4xl">
        <h2 className="text-center text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Agent Success Stories
        </h2>
        <p className="mt-4 text-center text-lg text-gray-600">
          Hear from agents who have transformed their business with UIP
        </p>
        <div className="mt-16 space-y-8 lg:space-y-10">
          {testimonials.map((testimonial) => (
            <figure
              key={testimonial.author}
              className="group relative rounded-2xl bg-white p-6 shadow-md transition-all hover:shadow-xl sm:p-8"
            >
              <div className="flex items-center gap-x-4 sm:gap-x-6">
                <img
                  className="h-12 w-12 rounded-full bg-gray-50 object-cover sm:h-14 sm:w-14"
                  src={testimonial.image}
                  alt={testimonial.author}
                />
                <div>
                  <div className="text-sm font-semibold leading-6 text-gray-900 sm:text-base">
                    {testimonial.author}
                  </div>
                  <div className="text-sm leading-6 text-gray-600">
                    {testimonial.role}
                  </div>
                </div>
              </div>
              <blockquote className="mt-6 text-base leading-7 text-gray-700 sm:mt-8 sm:text-lg">
                {testimonial.content}
              </blockquote>
              <div className="mt-4 flex gap-x-1 text-blue-400">
                {[...Array(testimonial.rating)].map((_, i) => (
                  <Star key={i} className="h-5 w-5 fill-current" />
                ))}
              </div>
            </figure>
          ))}
        </div>
      </div>
    </section>
  );
}