import { ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import Button from '../../ui/Button';

export default function TeamHero() {
  return (
    <section className="relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-br from-blue-50 to-white" />
      <div className="relative mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl lg:text-6xl">
            Meet Our Insurance Professionals
          </h1>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            A dedicated team of experts committed to protecting what matters most.
            With decades of combined experience, we're here to guide you through
            every step of your insurance journey.
          </p>
          <div className="mt-8 flex gap-4">
            <Link to="/contact">
              <Button size="lg" className="gap-2">
                Connect With Us
                <ArrowRight className="h-5 w-5" />
              </Button>
            </Link>
            <Link to="/careers">
              <Button size="lg" variant="outline">
                Join Our Team
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}