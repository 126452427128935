import { Heart, Activity, Stethoscope } from 'lucide-react';
import PlanCard from '../shared/PlanCard';

const livingBenefits = [
  {
    name: 'Critical Illness Benefit',
    description: 'Access your death benefit if diagnosed with a critical illness',
    icon: Activity,
    features: [
      'Early access to death benefit',
      'Coverage for major illnesses',
      'No restrictions on fund use',
      'Preserves remaining death benefit'
    ],
    benefits: [
      'Financial security during illness',
      'Maintain quality of life',
      'Cover medical expenses',
      'Protect retirement savings'
    ]
  },
  {
    name: 'Chronic Care Benefit',
    description: 'Protection for long-term care needs',
    icon: Heart,
    features: [
      'Monthly benefit payments',
      'Covers home healthcare',
      'Nursing home coverage',
      'Flexible care options'
    ],
    benefits: [
      'Maintain independence',
      'Protect family resources',
      'Professional care coverage',
      'Asset protection'
    ]
  },
  {
    name: 'Terminal Illness Benefit',
    description: 'Early benefit access for terminal conditions',
    icon: Stethoscope,
    features: [
      'Up to 95% death benefit access',
      'No premium during acceleration',
      'Guaranteed benefit amount',
      'Simple qualification process'
    ],
    benefits: [
      'Quality end-of-life care',
      'Financial peace of mind',
      'Family support options',
      'Legacy preservation'
    ]
  }
];

export default function LivingBenefits() {
  return (
    <div className="space-y-16">
      {/* Introduction */}
      <div className="rounded-2xl bg-gradient-to-br from-blue-50 to-white p-8">
        <h2 className="text-2xl font-bold text-gray-900">
          Life Insurance That Benefits You Today
        </h2>
        <p className="mt-2 text-gray-600">
          Access your life insurance benefits while living to help cover critical,
          chronic, or terminal illness expenses. These living benefits provide
          financial protection when you need it most.
        </p>
      </div>

      {/* Benefits Grid */}
      <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
        {livingBenefits.map((benefit) => (
          <PlanCard key={benefit.name} plan={benefit} />
        ))}
      </div>

      {/* Additional Information */}
      <div className="rounded-2xl bg-gray-50 p-8">
        <h3 className="text-lg font-semibold text-gray-900">
          Important Information
        </h3>
        <ul className="mt-4 space-y-3">
          <li className="flex items-center text-sm text-gray-600">
            <span className="mr-3 h-1.5 w-1.5 flex-none rounded-full bg-blue-600" />
            Living benefits are subject to eligibility requirements and may vary by state
          </li>
          <li className="flex items-center text-sm text-gray-600">
            <span className="mr-3 h-1.5 w-1.5 flex-none rounded-full bg-blue-600" />
            Accessing living benefits will reduce the death benefit amount
          </li>
          <li className="flex items-center text-sm text-gray-600">
            <span className="mr-3 h-1.5 w-1.5 flex-none rounded-full bg-blue-600" />
            Benefits may be taxable in certain circumstances
          </li>
        </ul>
      </div>
    </div>
  );
}