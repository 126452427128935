import { useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { Link } from 'react-router-dom';
import { Menu, X, ChevronDown } from 'lucide-react';
import { MAIN_NAV } from '../../constants/navigation';
import { cn } from '../../lib/utils';
import { useDisableScroll } from '../../hooks/useDisableScroll';
import { useClickAway } from '../../hooks/useClickAway';
import Logo from '../ui/Logo';

interface MobileMenuProps {
  isOpen: boolean;
  onClose: () => void;
}

function MobileMenu({ isOpen, onClose }: MobileMenuProps) {
  const [activeSection, setActiveSection] = useState<string | null>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  // Handle click outside
  useClickAway(menuRef, onClose);

  const toggleSection = (name: string) => {
    setActiveSection(current => current === name ? null : name);
  };

  return createPortal(
    <div
      className={cn(
        'fixed inset-0 z-50 flex items-start lg:hidden',
        'transition-all duration-300',
        isOpen ? 'backdrop-blur-[8px] bg-black/40' : 'pointer-events-none backdrop-blur-none bg-transparent'
      )}
    >
      {/* Menu Panel */}
      <div
        ref={menuRef}
        className={cn(
          'h-full w-[85%] max-w-md bg-white/95',
          'transform transition-all duration-300 ease-out',
          'flex flex-col',
          'shadow-2xl backdrop-blur-xl',
          isOpen ? 'translate-x-0' : '-translate-x-full'
        )}
      >
        {/* Header */}
        <div className="flex h-32 items-center justify-between border-b border-gray-100/50 px-8 backdrop-blur-xl">
          <Logo size="lg" />
        </div>

        {/* Navigation */}
        <nav className="flex-1 overflow-y-auto overscroll-contain px-2 py-4">
          <ul className="space-y-2">
            {MAIN_NAV.map((item) => (
              <li key={item.name}>
                {item.items ? (
                  <div>
                    <button
                      onClick={() => toggleSection(item.name)}
                      className={cn(
                        'flex w-full items-center justify-between rounded-lg p-3',
                        'text-base font-medium',
                        'transition-all duration-200',
                        activeSection === item.name
                          ? 'bg-blue-50/80 text-blue-600 shadow-sm'
                          : 'text-gray-900 hover:bg-gray-50/80'
                      )}
                    >
                      <div className="flex items-center space-x-3">
                        {item.icon && (
                          <item.icon
                            className={cn(
                              'h-5 w-5 transition-colors',
                              activeSection === item.name
                                ? 'text-blue-600'
                                : 'text-gray-500'
                            )}
                          />
                        )}
                        <span>{item.name}</span>
                      </div>
                      <ChevronDown 
                        className={cn(
                          'h-5 w-5 transition-transform duration-200',
                          activeSection === item.name 
                            ? 'rotate-180 text-blue-600' 
                            : 'text-gray-400'
                        )}
                      />
                    </button>
                    
                    <div
                      className={cn(
                        'mt-1 overflow-hidden rounded-lg',
                        'transition-all duration-200 ease-in-out',
                        activeSection === item.name ? 'max-h-[400px] opacity-100' : 'max-h-0 opacity-0'
                      )}
                    >
                      {item.items.map((subItem) => (
                        <Link
                          key={subItem.name}
                          to={subItem.href}
                          onClick={onClose}
                          className={cn(
                            'block rounded-lg px-8 py-3',
                            'transition-all duration-200',
                            'hover:bg-blue-50/50 hover:shadow-sm active:bg-blue-100/50'
                          )}
                        >
                          <div className="text-sm text-gray-600 transition-colors hover:text-gray-900">
                            <div className="font-medium">{subItem.name}</div>
                            {subItem.description && (
                              <p className="mt-0.5 text-xs text-gray-500">
                                {subItem.description}
                              </p>
                            )}
                          </div>
                        </Link>
                      ))}
                    </div>
                  </div>
                ) : (
                  <Link
                    to={item.href}
                    onClick={onClose}
                    className={cn(
                      'flex items-center space-x-3 rounded-lg p-3',
                      'text-base font-medium text-gray-900',
                      'transition-all duration-200',
                      'hover:bg-gray-50/80 hover:shadow-sm active:bg-gray-100/80'
                    )}
                  >
                    {item.icon && (
                      <item.icon className="h-5 w-5 text-gray-500" />
                    )}
                    <span>{item.name}</span>
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </nav>

        {/* Footer */}
        <div className="border-t border-gray-100/50 p-4 backdrop-blur-xl">
          <Link
            to="/contact"
            onClick={onClose}
            className={cn(
              'flex items-center justify-center rounded-lg bg-blue-600 px-4 py-3',
              'text-base font-semibold text-white',
              'transition-all duration-200',
              'hover:bg-blue-700 hover:shadow-md active:bg-blue-800'
            )}
          >
            Get Started
          </Link>
        </div>
      </div>
    </div>,
    document.body
  );
}

export default function MobileNav() {
  const [isOpen, setIsOpen] = useState(false);
  
  // Lock scroll when menu is open
  useDisableScroll(isOpen);

  // Close on escape key
  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') setIsOpen(false);
    };

    if (isOpen) {
      window.addEventListener('keydown', handleEscape);
      return () => window.removeEventListener('keydown', handleEscape);
    }
  }, [isOpen]);

  return (
    <div className="lg:hidden">
      <div className="fixed top-0 left-0 right-0 z-50 flex h-28 items-center justify-between bg-white/90 px-8 backdrop-blur-md shadow-sm">
        <Logo size="md" />
        <button
          type="button"
          onClick={() => setIsOpen(!isOpen)}
          className={cn(
            'flex h-12 w-12 items-center justify-center rounded-lg',
            'transition-all duration-200',
            'hover:bg-gray-100/80 active:bg-gray-200/80',
            'focus:outline-none focus-visible:ring-2',
            'focus-visible:ring-blue-500 focus-visible:ring-offset-2',
            isOpen && 'bg-gray-100/80'
          )}
        >
          <span className="sr-only">
            {isOpen ? 'Close menu' : 'Open menu'}
          </span>
          {isOpen ? (
            <X className="h-6 w-6 text-gray-900" />
          ) : (
            <Menu className="h-6 w-6 text-gray-900" />
          )}
        </button>
      </div>

      <MobileMenu isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </div>
  );
}
