import { useEffect, useRef } from 'react';
import { COMPANY_INFO } from '../../../constants';

const milestones = [
  {
    year: COMPANY_INFO.founded,
    title: 'UIP is Founded',
    description: 'Started with a vision to transform insurance distribution.',
    image: 'https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?w=800&auto=format',
  },
  {
    year: '2019',
    title: 'Nationwide Expansion',
    description: 'Expanded operations to serve agents across all 50 states.',
    image: 'https://images.unsplash.com/photo-1557804506-669a67965ba0?w=800&auto=format',
  },
  {
    year: '2021',
    title: 'Digital Transformation',
    description: 'Launched innovative technology platform for agents.',
    image: 'https://images.unsplash.com/photo-1460925895917-afdab827c52f?w=800&auto=format',
  },
  {
    year: '2023',
    title: 'Industry Leadership',
    description: 'Recognized as a leading IMO in product innovation.',
    image: 'https://images.unsplash.com/photo-1552664730-d307ca884978?w=800&auto=format',
  },
];

export default function Timeline() {
  const timelineRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!timelineRef.current) return;
    
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('opacity-100', 'translate-y-0');
          }
        });
      },
      { 
        threshold: 0.2,
        rootMargin: '50px'
      }
    );

    const timelineItems = timelineRef.current?.querySelectorAll('.timeline-item');
    timelineItems?.forEach((item) => observer.observe(item));

    return () => observer.disconnect();
  }, []);

  return (
    <section className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Our Growth Story
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            From our founding to today, we've consistently innovated and grown to
            better serve our agents and their clients.
          </p>
        </div>

        <div
          ref={timelineRef}
          className="mx-auto mt-16 max-w-4xl space-y-16"
        >
          {milestones.map((milestone, index) => (
            <div
              key={milestone.year}
              className={`timeline-item opacity-0 translate-y-8 transition-all duration-700`}
              style={{ transitionDelay: `${index * 200}ms` }}
            >
              <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
                <div
                  className={`flex flex-col justify-center ${
                    index % 2 === 0 ? 'lg:order-1' : 'lg:order-2'
                  }`}
                >
                  <div className="flex items-center gap-4">
                    <span className="rounded-full bg-blue-100 px-3 py-1 text-sm font-semibold text-blue-600">
                      {milestone.year}
                    </span>
                  </div>
                  <h3 className="mt-4 text-xl font-semibold text-gray-900">
                    {milestone.title}
                  </h3>
                  <p className="mt-2 text-base text-gray-600">
                    {milestone.description}
                  </p>
                </div>
                <div
                  className={`relative ${
                    index % 2 === 0 ? 'lg:order-2' : 'lg:order-1'
                  }`}
                >
                  <div className="aspect-[4/3] overflow-hidden rounded-2xl bg-gray-100">
                    <img
                      src={milestone.image}
                      alt={milestone.title}
                      className="h-full w-full object-cover"
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}