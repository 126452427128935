import { useState } from 'react';
import { Shield, Heart, Stethoscope } from 'lucide-react';
import PlanCard from '../../components/insurance/shared/PlanCard';
import ResourceCenter from '../../components/insurance/shared/ResourceCenter';

const plans = [
  {
    name: 'Individual Health Plans',
    description: 'Comprehensive coverage for individuals',
    icon: Shield,
    features: [
      'Wide network of providers',
      'Prescription drug coverage',
      'Preventive care included',
      'Specialist visits'
    ],
    benefits: [
      'Affordable monthly premiums',
      'Low out-of-pocket costs',
      'No referral needed',
      'Virtual care options'
    ]
  },
  {
    name: 'Family Health Plans',
    description: 'Protection for the whole family',
    icon: Heart,
    features: [
      'Family deductible options',
      'Pediatric dental & vision',
      'Maternity coverage',
      'Wellness programs'
    ],
    benefits: [
      'Family premium savings',
      'Preventive care at no cost',
      'Children covered to age 26',
      'Family prescription savings'
    ]
  },
  {
    name: 'HSA-Compatible Plans',
    description: 'Tax-advantaged health savings',
    icon: Stethoscope,
    features: [
      'High-deductible health plan',
      'HSA contribution options',
      'Preventive coverage',
      'Investment opportunities'
    ],
    benefits: [
      'Tax-advantaged savings',
      'Lower monthly premiums',
      'Investment potential',
      'Portable HSA funds'
    ]
  }
];

export default function HealthInsurancePage() {
  const [activeTab, setActiveTab] = useState('plans');

  return (
    <main className="min-h-screen bg-gray-50 pt-16">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        {/* Page Header */}
        <div className="py-16 lg:py-24">
          <div className="text-center">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              Health Insurance Solutions
            </h1>
            <p className="mx-auto mt-6 max-w-2xl text-lg text-gray-600">
              Comprehensive health insurance solutions designed to provide quality
              healthcare coverage at competitive rates.
            </p>
          </div>
        </div>

        {/* Navigation Tabs */}
        <nav className="mb-8 flex justify-center space-x-4" aria-label="Health Insurance Navigation">
          <button
            onClick={() => setActiveTab('plans')}
            className={`rounded-lg px-4 py-2 text-sm font-medium transition-colors
              ${activeTab === 'plans' 
                ? 'bg-blue-600 text-white' 
                : 'bg-white text-gray-700 hover:bg-gray-100'}`}
          >
            Available Plans
          </button>
          <button
            onClick={() => setActiveTab('networks')}
            className={`rounded-lg px-4 py-2 text-sm font-medium transition-colors
              ${activeTab === 'networks' 
                ? 'bg-blue-600 text-white' 
                : 'bg-white text-gray-700 hover:bg-gray-100'}`}
          >
            Provider Networks
          </button>
        </nav>

        {/* Plans Grid */}
        <div className="mb-16 grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          {plans.map((plan) => (
            <PlanCard key={plan.name} plan={plan} />
          ))}
        </div>

        {/* Resource Center */}
        <ResourceCenter productType="health" />
      </div>
    </main>
  );
}