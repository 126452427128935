import { ArrowRight, Shield } from 'lucide-react';
import { Link } from 'react-router-dom';
import Button from '../ui/Button';

export default function Hero() {
  return (
    <div className="relative overflow-hidden bg-white">
      <div className="absolute inset-0 bg-gradient-to-br from-blue-50 to-white" />
      <div className="relative mx-auto max-w-7xl px-4 pt-4 pb-12 sm:py-16 md:py-20 lg:py-24 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-12">
          <div className="flex flex-col justify-center">
            <div className="inline-flex items-center gap-2 rounded-full bg-blue-50 px-3 py-1.5 sm:px-4 sm:py-2 text-sm font-medium text-blue-700">
              <Shield className="h-4 w-4" />
              <span className="line-clamp-1">Trusted by Leading IMOs</span>
            </div>
            <h1 className="mt-4 sm:mt-6 text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold tracking-tight text-gray-900">
              <span className="block">Empowering Agents,</span>{' '}
              <span className="block text-blue-600">Protecting Families</span>
            </h1>
            <p className="mt-4 sm:mt-6 text-base sm:text-lg leading-7 sm:leading-8 text-gray-600 max-w-xl">
              Join UIP, a leading IMO/FMO providing comprehensive insurance solutions, 
              cutting-edge technology, and unparalleled support for insurance professionals.
            </p>
            <div className="mt-6 sm:mt-8 flex flex-col sm:flex-row gap-3 sm:gap-4">
              <Link to="/contact" className="w-full sm:w-auto">
                <Button size="lg" className="w-full sm:w-auto gap-2 transition-transform hover:-translate-y-0.5">
                  Get Started
                  <ArrowRight className="h-5 w-5" />
                </Button>
              </Link>
              <Link to="/contact" className="w-full sm:w-auto">
                <Button size="lg" variant="outline" className="w-full sm:w-auto transition-transform hover:-translate-y-0.5">
                  Contact Us
                </Button>
              </Link>
            </div>
            <div className="mt-8 sm:mt-12 flex items-center gap-6 sm:gap-8 overflow-x-auto pb-4 sm:pb-0">
              {[...Array(3)].map((_, i) => (
                <img
                  key={i}
                  src={`https://images.unsplash.com/photo-1560179707-f14e90ef3623?w=100&h=50&fit=crop&auto=format`}
                  alt="Partner company logo"
                  className="h-8 sm:h-12 w-auto flex-shrink-0 grayscale transition hover:grayscale-0"
                />
              ))}
            </div>
          </div>
          <div className="relative mt-8 lg:mt-0">
            <div className="absolute -right-4 top-1/2 h-48 sm:h-72 w-48 sm:w-72 -translate-y-1/2 rounded-full bg-blue-200/50 blur-3xl" />
            <div className="relative aspect-[4/3] overflow-hidden rounded-xl sm:rounded-2xl bg-gray-100 shadow-xl sm:shadow-2xl">
              <img
                src="https://images.unsplash.com/photo-1573164713714-d95e436ab8d6?w=800&auto=format"
                alt="Insurance professionals helping families"
                className="h-full w-full object-cover"
                loading="eager"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
