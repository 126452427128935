import { Megaphone, Mail, Users, Target } from 'lucide-react';
import Button from '../../components/ui/Button';

const marketingTools = [
  {
    name: 'Email Marketing',
    description: 'Professional email templates and campaign tools',
    icon: Mail,
    features: [
      'Customizable templates',
      'Automated campaigns',
      'Performance tracking',
      'Compliance approved'
    ]
  },
  {
    name: 'Social Media',
    description: 'Ready-to-use social content and graphics',
    icon: Users,
    features: [
      'Content calendar',
      'Post templates',
      'Image library',
      'Engagement tools'
    ]
  },
  {
    name: 'Lead Generation',
    description: 'Tools and resources for attracting prospects',
    icon: Target,
    features: [
      'Landing pages',
      'Lead magnets',
      'Contact forms',
      'Analytics dashboard'
    ]
  }
];

export default function MarketingPage() {
  return (
    <main className="min-h-screen bg-gray-50 pt-16">
      <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8">
        {/* Page Header */}
        <div className="text-center">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900">
            Marketing Resources
          </h1>
          <p className="mx-auto mt-6 max-w-2xl text-lg text-gray-600">
            Access professional marketing tools and resources to grow your business
            and reach more clients.
          </p>
        </div>

        {/* Marketing Tools Grid */}
        <div className="mt-16 grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          {marketingTools.map((tool) => (
            <div
              key={tool.name}
              className="group relative overflow-hidden rounded-2xl bg-white p-8 shadow-lg ring-1 ring-gray-200/50 transition-all hover:shadow-xl"
            >
              <div className="absolute inset-0 bg-gradient-to-br from-blue-50/50 via-white to-white opacity-0 transition-opacity group-hover:opacity-100" />
              
              <div className="relative">
                <div className="flex items-center gap-4">
                  <div className="rounded-lg bg-blue-50 p-2 text-blue-600">
                    <tool.icon className="h-6 w-6" />
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold text-gray-900">
                      {tool.name}
                    </h3>
                    <p className="text-sm text-gray-600">{tool.description}</p>
                  </div>
                </div>

                <ul className="mt-8 space-y-3">
                  {tool.features.map((feature) => (
                    <li
                      key={feature}
                      className="flex items-center text-sm text-gray-600"
                    >
                      <span className="mr-3 h-1.5 w-1.5 flex-none rounded-full bg-blue-600" />
                      {feature}
                    </li>
                  ))}
                </ul>

                <div className="mt-8">
                  <Button
                    variant="outline"
                    className="w-full justify-center transition-transform hover:-translate-y-0.5"
                  >
                    Access Tools
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Marketing Support */}
        <div className="mt-16 rounded-2xl bg-blue-50 p-8">
          <div className="flex items-center gap-4">
            <div className="rounded-lg bg-blue-600 p-2 text-white">
              <Megaphone className="h-6 w-6" />
            </div>
            <h2 className="text-xl font-semibold text-gray-900">
              Need Marketing Support?
            </h2>
          </div>
          <p className="mt-4 text-gray-600">
            Our marketing team is here to help you create and execute effective
            campaigns. Contact us for personalized assistance.
          </p>
          <div className="mt-6">
            <Button>Contact Marketing Team</Button>
          </div>
        </div>
      </div>
    </main>
  );
}