import Culture from '../../components/sections/about/Culture';
import CoreValues from '../../components/sections/about/CoreValues';
import Testimonials from '../../components/sections/Testimonials';

export default function CulturePage() {
  return (
    <main className="min-h-screen pt-16">
      <Culture />
      <CoreValues />
      <Testimonials />
    </main>
  );
}