import { type LucideIcon } from 'lucide-react';
import { cn } from '../../../lib/utils';

interface PlanCardProps {
  plan: {
    name: string;
    description: string;
    icon: LucideIcon;
    features: string[];
    benefits: string[];
  };
  className?: string;
}

export default function PlanCard({ plan, className }: PlanCardProps) {
  return (
    <div className={cn(
      "group relative overflow-hidden rounded-2xl bg-white p-8 shadow-lg transition-all hover:shadow-xl",
      className
    )}>
      <div className="absolute inset-0 bg-gradient-to-br from-blue-50/50 via-white to-white opacity-0 transition-opacity group-hover:opacity-100" />
      
      <div className="relative">
        {/* Header */}
        <div className="flex items-center gap-4">
          <div className="rounded-lg bg-blue-50 p-2 text-blue-600">
            <plan.icon className="h-6 w-6" />
          </div>
          <div>
            <h3 className="text-xl font-semibold text-gray-900">{plan.name}</h3>
            <p className="text-sm text-gray-600">{plan.description}</p>
          </div>
        </div>

        {/* Features */}
        <div className="mt-8">
          <h4 className="text-sm font-medium text-gray-900">Key Features</h4>
          <ul className="mt-4 space-y-3">
            {plan.features.map((feature) => (
              <li key={feature} className="flex items-center text-sm text-gray-600">
                <span className="mr-3 h-1.5 w-1.5 flex-none rounded-full bg-blue-600" />
                {feature}
              </li>
            ))}
          </ul>
        </div>

        {/* Benefits */}
        <div className="mt-8">
          <h4 className="text-sm font-medium text-gray-900">Benefits</h4>
          <ul className="mt-4 space-y-3">
            {plan.benefits.map((benefit) => (
              <li key={benefit} className="flex items-center text-sm text-gray-600">
                <span className="mr-3 h-1.5 w-1.5 flex-none rounded-full bg-green-600" />
                {benefit}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}