import { ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import Button from '../../ui/Button';
import { COMPANY_INFO } from '../../../constants';

export default function Hero() {
  return (
    <section className="relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-br from-blue-50 to-white" />
      <div className="relative mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8">
        <div className="grid grid-cols-1 gap-12 lg:grid-cols-2 lg:gap-8">
          <div className="flex flex-col justify-center">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              Our Journey in Insurance Excellence
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Since {COMPANY_INFO.founded}, UIP has been transforming the insurance
              landscape through innovation, integrity, and a commitment to
              excellence.
            </p>
            <div className="mt-8 flex gap-4">
              <Link to="/team">
                <Button size="lg" className="gap-2">
                  Meet Our Team
                  <ArrowRight className="h-5 w-5" />
                </Button>
              </Link>
              <Link to="/contact">
                <Button size="lg" variant="outline">
                  Get in Touch
                </Button>
              </Link>
            </div>
          </div>
          
          <div className="relative">
            <div className="absolute -right-4 top-1/2 h-72 w-72 -translate-y-1/2 rounded-full bg-blue-200 blur-3xl" />
            <div className="relative aspect-[4/3] overflow-hidden rounded-2xl bg-gray-100 shadow-2xl">
              <img
                src="https://images.unsplash.com/photo-1522071820081-009f0129c71c?w=800&auto=format"
                alt="UIP team collaboration"
                className="h-full w-full object-cover"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}