import { Star, Award, TrendingUp } from 'lucide-react';
import CarrierCard from './CarrierCard';

const carriers = [
  {
    name: 'Pinnacle Life & Health',
    logo: 'https://images.unsplash.com/photo-1560179707-f14e90ef3623?w=200&h=100&fit=crop&auto=format',
    rating: 'A+ (Superior)',
    specialties: ['Life Insurance', 'Medicare Solutions', 'Group Benefits'],
    partnerLevel: 'Elite Partner',
    features: [
      'Comprehensive product portfolio',
      'Advanced underwriting platform',
      'Industry-leading commission rates',
      'Dedicated support team'
    ]
  },
  {
    name: 'Guardian Shield Insurance',
    logo: 'https://images.unsplash.com/photo-1560179707-f14e90ef3623?w=200&h=100&fit=crop&auto=format',
    rating: 'A (Excellent)',
    specialties: ['Final Expense', 'Term Life', 'Critical Illness'],
    partnerLevel: 'Premier Partner',
    features: [
      'Simplified issue products',
      'Next-day policy issue',
      'Electronic applications',
      'Agent marketing support'
    ]
  },
  {
    name: 'Liberty Health Solutions',
    logo: 'https://images.unsplash.com/photo-1560179707-f14e90ef3623?w=200&h=100&fit=crop&auto=format',
    rating: 'A (Excellent)',
    specialties: ['Medicare Advantage', 'Supplement Plans', 'Prescription Coverage'],
    partnerLevel: 'Strategic Partner',
    features: [
      'Competitive Medicare products',
      'Year-round enrollment tools',
      'Compliance support',
      'Training resources'
    ]
  }
];

const highlights = [
  {
    title: 'Elite Partnerships',
    description: 'Access top-rated carriers with exclusive products and enhanced compensation',
    icon: Star,
  },
  {
    title: 'Quality Assurance',
    description: 'Partner with financially strong, reliable insurance providers',
    icon: Award,
  },
  {
    title: 'Growth Opportunities',
    description: 'Unlock additional benefits through production achievements',
    icon: TrendingUp,
  },
];

export default function FeaturedCarriers() {
  return (
    <section className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Featured Carrier Partners
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Partner with industry-leading insurance carriers offering competitive 
            products and reliable coverage for your clients.
          </p>
        </div>

        {/* Highlights */}
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            {highlights.map((highlight) => (
              <div key={highlight.title} className="flex flex-col">
                <dt className="flex items-center gap-x-3 text-lg font-semibold leading-7 text-gray-900">
                  <highlight.icon
                    className="h-6 w-6 flex-none text-blue-600"
                    aria-hidden="true"
                  />
                  {highlight.title}
                </dt>
                <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
                  <p className="flex-auto">{highlight.description}</p>
                </dd>
              </div>
            ))}
          </dl>
        </div>

        {/* Carrier Cards */}
        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-12 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {carriers.map((carrier) => (
            <CarrierCard key={carrier.name} carrier={carrier} />
          ))}
        </div>
      </div>
    </section>
  );
}