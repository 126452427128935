import { Facebook, Github, Linkedin, Twitter } from 'lucide-react';
import { Link } from 'react-router-dom';

const navigation = {
  solutions: [
    { name: 'Life Insurance', href: '/insurance/life' },
    { name: 'Health Insurance', href: '/insurance/health' },
    { name: 'Medicare Solutions', href: '/insurance/medicare' },
    { name: 'Annuity Solutions', href: '/insurance/annuities' },
  ],
  company: [
    { name: 'Our Story', href: '/about/story' },
    { name: 'Leadership Team', href: '/about/leadership' },
    { name: 'Company Culture', href: '/about/culture' },
    { name: 'News & Updates', href: '/about/news' },
  ],
  resources: [
    { name: 'Training & Development', href: '/resources/training' },
    { name: 'Document Center', href: '/resources/documents' },
    { name: 'Marketing Resources', href: '/resources/marketing' },
    { name: 'Commission Structure', href: '/resources/commissions' },
  ],
  legal: [
    { name: 'Privacy', href: '/privacy' },
    { name: 'Terms', href: '/terms' },
    { name: 'Legal Disclosures', href: '/legal' },
  ],
  social: [
    {
      name: 'LinkedIn',
      href: 'https://www.linkedin.com/company/uip-insurance',
      icon: Linkedin,
    },
    {
      name: 'Twitter',
      href: 'https://twitter.com/UIPInsurance',
      icon: Twitter,
    },
    {
      name: 'Facebook',
      href: 'https://www.facebook.com/UIPInsurance',
      icon: Facebook,
    },
    {
      name: 'GitHub',
      href: 'https://github.com/UIPInsurance',
      icon: Github,
    },
  ],
};

export default function Footer() {
  return (
    <footer className="bg-gray-900" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8">
            <Link to="/" className="text-2xl font-bold text-white">
              UIP
            </Link>
            <p className="text-sm leading-6 text-gray-300">
              Empowering insurance distribution with enterprise technology solutions
              and professional services.
            </p>
            <div className="flex space-x-6">
              {navigation.social.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-gray-400 hover:text-gray-300"
                >
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
            </div>
          </div>
          <div className="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold leading-6 text-white">
                  Solutions
                </h3>
                <ul role="list" className="mt-6 space-y-4">
                  {navigation.solutions.map((item) => (
                    <li key={item.name}>
                      <Link
                        to={item.href}
                        className="text-sm leading-6 text-gray-300 hover:text-white"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-10 md:mt-0">
                <h3 className="text-sm font-semibold leading-6 text-white">
                  Company
                </h3>
                <ul role="list" className="mt-6 space-y-4">
                  {navigation.company.map((item) => (
                    <li key={item.name}>
                      <Link
                        to={item.href}
                        className="text-sm leading-6 text-gray-300 hover:text-white"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold leading-6 text-white">
                  Resources
                </h3>
                <ul role="list" className="mt-6 space-y-4">
                  {navigation.resources.map((item) => (
                    <li key={item.name}>
                      <Link
                        to={item.href}
                        className="text-sm leading-6 text-gray-300 hover:text-white"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-10 md:mt-0">
                <h3 className="text-sm font-semibold leading-6 text-white">
                  Legal
                </h3>
                <ul role="list" className="mt-6 space-y-4">
                  {navigation.legal.map((item) => (
                    <li key={item.name}>
                      <Link
                        to={item.href}
                        className="text-sm leading-6 text-gray-300 hover:text-white"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-16 border-t border-white/10 pt-8 sm:mt-20 lg:mt-24">
          <p className="text-xs leading-5 text-gray-400">
            &copy; {new Date().getFullYear()} United Insurance Professionals, Inc. All rights
            reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}