import { Heart, ShieldCheck, Users } from 'lucide-react';

const values = [
  {
    icon: Heart,
    title: 'Family-First Culture',
    description: 'Building lasting relationships through trust and mutual support.',
  },
  {
    icon: ShieldCheck,
    title: 'Unwavering Integrity',
    description: 'Maintaining the highest standards of ethics and professionalism.',
  },
  {
    icon: Users,
    title: 'Empowering Success',
    description: 'Providing the tools and support for our agents to thrive.',
  },
];

export default function Mission() {
  return (
    <section className="relative overflow-hidden bg-gradient-to-r from-blue-50 to-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Our Mission
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            To empower insurance professionals with innovative solutions and
            unwavering support, enabling them to protect and serve families across
            America.
          </p>
        </div>

        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            {values.map((value) => (
              <div key={value.title} className="flex flex-col">
                <dt className="flex items-center gap-x-3 text-lg font-semibold leading-7 text-gray-900">
                  <value.icon
                    className="h-6 w-6 flex-none text-blue-600"
                    aria-hidden="true"
                  />
                  {value.title}
                </dt>
                <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
                  <p className="flex-auto">{value.description}</p>
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </section>
  );
}