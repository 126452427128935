import { useState } from 'react';
import { PiggyBank, TrendingUp, Lock } from 'lucide-react';
import PlanCard from '../../components/insurance/shared/PlanCard';
import ResourceCenter from '../../components/insurance/shared/ResourceCenter';

const plans = [
  {
    name: 'Fixed Annuities',
    description: 'Guaranteed interest rates and principal protection',
    icon: Lock,
    features: [
      'Guaranteed interest rates',
      'Principal protection',
      'Tax-deferred growth',
      'Predictable income options'
    ],
    benefits: [
      'Stable returns',
      'No market risk',
      'Guaranteed income',
      'Estate planning tool'
    ]
  },
  {
    name: 'Fixed Indexed Annuities',
    description: 'Market-linked potential with downside protection',
    icon: TrendingUp,
    features: [
      'Index-linked returns',
      'Principal protection',
      'Multiple index options',
      'Flexible crediting methods'
    ],
    benefits: [
      'Growth potential',
      'Downside protection',
      'Tax-deferred growth',
      'Optional income riders'
    ]
  },
  {
    name: 'Multi-Year Guarantee Annuities',
    description: 'Locked-in rates for extended periods',
    icon: PiggyBank,
    features: [
      'Guaranteed rate period',
      'Multiple term options',
      'Competitive rates',
      'Systematic growth'
    ],
    benefits: [
      'Rate certainty',
      'Predictable growth',
      'Tax advantages',
      'Legacy planning'
    ]
  }
];

export default function AnnuityPage() {
  const [activeTab, setActiveTab] = useState('products');

  return (
    <main className="min-h-screen bg-gray-50 pt-16">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        {/* Page Header */}
        <div className="py-16 lg:py-24">
          <div className="text-center">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              Annuity Solutions
            </h1>
            <p className="mx-auto mt-6 max-w-2xl text-lg text-gray-600">
              Secure retirement solutions with competitive rates and flexible
              options to meet your clients' long-term financial goals.
            </p>
          </div>
        </div>

        {/* Navigation Tabs */}
        <nav className="mb-8 flex justify-center space-x-4" aria-label="Annuity Navigation">
          <button
            onClick={() => setActiveTab('products')}
            className={`rounded-lg px-4 py-2 text-sm font-medium transition-colors
              ${activeTab === 'products' 
                ? 'bg-blue-600 text-white' 
                : 'bg-white text-gray-700 hover:bg-gray-100'}`}
          >
            Products
          </button>
          <button
            onClick={() => setActiveTab('features')}
            className={`rounded-lg px-4 py-2 text-sm font-medium transition-colors
              ${activeTab === 'features' 
                ? 'bg-blue-600 text-white' 
                : 'bg-white text-gray-700 hover:bg-gray-100'}`}
          >
            Features & Benefits
          </button>
        </nav>

        {/* Plans Grid */}
        <div className="mb-16 grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          {plans.map((plan) => (
            <PlanCard key={plan.name} plan={plan} />
          ))}
        </div>

        {/* Important Information */}
        <div className="mb-16 rounded-2xl bg-blue-50 p-8">
          <h2 className="text-lg font-semibold text-gray-900">
            Important Information
          </h2>
          <ul className="mt-4 space-y-3">
            <li className="flex items-center text-sm text-gray-600">
              <span className="mr-3 h-1.5 w-1.5 flex-none rounded-full bg-blue-600" />
              Guarantees are backed by the financial strength of the issuing insurance company
            </li>
            <li className="flex items-center text-sm text-gray-600">
              <span className="mr-3 h-1.5 w-1.5 flex-none rounded-full bg-blue-600" />
              Surrender charges may apply for early withdrawals
            </li>
            <li className="flex items-center text-sm text-gray-600">
              <span className="mr-3 h-1.5 w-1.5 flex-none rounded-full bg-blue-600" />
              Tax-deferred growth assumes the contract is properly structured
            </li>
          </ul>
        </div>

        {/* Resource Center */}
        <ResourceCenter productType="life" />
      </div>
    </main>
  );
}