import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useEffect, useCallback } from 'react';
import { AnimatePresence } from 'framer-motion';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import PageTransition from './components/ui/PageTransition';
import ErrorBoundary from './components/ui/ErrorBoundary';
import { trackPageView } from './lib/analytics';

import HomePage from './pages/HomePage';
import LifeInsurancePage from './pages/insurance/LifeInsurancePage';
import HealthInsurancePage from './pages/insurance/HealthInsurancePage';
import AnnuityPage from './pages/insurance/AnnuityPage';
import MedicarePage from './pages/insurance/MedicarePage';
import TrainingPage from './pages/resources/TrainingPage';
import DocumentsPage from './pages/resources/DocumentsPage';
import MarketingPage from './pages/resources/MarketingPage';
import CommissionsPage from './pages/resources/CommissionsPage';
import CarrierDirectoryPage from './pages/carriers/CarrierDirectoryPage';
import CarrierProductsPage from './pages/carriers/CarrierProductsPage';
import PartnerProgramsPage from './pages/carriers/PartnerProgramsPage';
import AboutStoryPage from './pages/about/StoryPage';
import AboutLeadershipPage from './pages/about/LeadershipPage';
import AboutCulturePage from './pages/about/CulturePage';
import AboutNewsPage from './pages/about/NewsPage';
import PrivacyPolicyPage from './pages/legal/PrivacyPolicyPage';
import TermsOfServicePage from './pages/legal/TermsOfServicePage';
import LegalDisclosuresPage from './pages/legal/LegalDisclosuresPage';
import NotFoundPage from './pages/error/NotFoundPage';
import MaintenancePage from './pages/error/MaintenancePage';
import AccessDeniedPage from './pages/error/AccessDeniedPage';
import ContactPage from './pages/ContactPage';
import { useLocation } from 'react-router-dom';

function AppRoutes() {
  const location = useLocation();

  useEffect(() => {
    trackPageView(location.pathname, location.search);
  }, [location.pathname, location.search]);

  return (
    <AnimatePresence mode="wait">
      <PageTransition>
        <Routes>
          <Route path="/" element={<HomePage />} />
           
          {/* Insurance Solutions */}
          <Route path="/insurance/life" element={<LifeInsurancePage />} />
          <Route path="/insurance/health" element={<HealthInsurancePage />} />
          <Route path="/insurance/medicare" element={<MedicarePage />} />
          <Route path="/insurance/annuities" element={<AnnuityPage />} />
           
           {/* Agent Resources */}
          <Route path="/resources/training" element={<TrainingPage />} />
          <Route path="/resources/documents" element={<DocumentsPage />} />
          <Route path="/resources/marketing" element={<MarketingPage />} />
          <Route path="/resources/commissions" element={<CommissionsPage />} />
           
           {/* Carrier Network */}
          <Route path="/carriers/directory" element={<CarrierDirectoryPage />} />
          <Route path="/carriers/products" element={<CarrierProductsPage />} />
          <Route path="/carriers/programs" element={<PartnerProgramsPage />} />
           
           {/* About Us */}
          <Route path="/about/story" element={<AboutStoryPage />} />
          <Route path="/about/leadership" element={<AboutLeadershipPage />} />
          <Route path="/about/culture" element={<AboutCulturePage />} />
          <Route path="/about/news" element={<AboutNewsPage />} />
           
           {/* Legal Pages */}
          <Route path="/privacy" element={<PrivacyPolicyPage />} />
          <Route path="/terms" element={<TermsOfServicePage />} />
          <Route path="/legal" element={<LegalDisclosuresPage />} />
           
          {/* Utility Pages */}
          <Route path="/maintenance" element={<MaintenancePage />} />
          <Route path="/access-denied" element={<AccessDeniedPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </PageTransition>
    </AnimatePresence>
   );
 }
 
 export default function App() {
   return (
     <Router>
      <ErrorBoundary>
        <Navigation />
        <AppRoutes />
        <Footer />
      </ErrorBoundary>
    </Router>
   );
 }