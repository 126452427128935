import { type LucideIcon, Heart, Users, Target, GraduationCap, HeartHandshake, Shield } from 'lucide-react';

/**
 * Core value definition with icon and details
 */
export interface CoreValue {
  readonly title: string;
  readonly description: string;
  readonly icon: LucideIcon;
  readonly examples: readonly string[];
}

/**
 * Company culture pillar definition
 */
export interface CulturePillar {
  readonly title: string;
  readonly description: string;
  readonly practices: readonly string[];
}

/**
 * UIP's core values that guide our mission
 */
export const CORE_VALUES: readonly CoreValue[] = [
  {
    title: 'Family-First Culture',
    description: 'Building lasting relationships through trust and support',
    icon: Heart,
    examples: [
      'Regular team celebrations and recognition',
      'Mentorship program for new agents',
      'Work-life balance initiatives',
      'Inclusive community events',
    ],
  },
  {
    title: 'Agent Empowerment',
    description: 'Providing the tools and freedom to build successful businesses',
    icon: Users,
    examples: [
      'Comprehensive training programs',
      'Advanced technology platform',
      'Marketing resources and support',
      'Flexible business models',
    ],
  },
  {
    title: 'Client-Centric Focus',
    description: 'Delivering exceptional service and protection',
    icon: Target,
    examples: [
      'Personalized coverage solutions',
      'Responsive customer support',
      'Regular policy reviews',
      'Claims advocacy assistance',
    ],
  },
  {
    title: 'Professional Growth',
    description: 'Investing in continuous learning and development',
    icon: GraduationCap,
    examples: [
      'Industry certification support',
      'Leadership development tracks',
      'Skills enhancement workshops',
      'Career advancement opportunities',
    ],
  },
  {
    title: 'Collaborative Success',
    description: 'Working together to achieve shared goals',
    icon: HeartHandshake,
    examples: [
      'Team-based problem solving',
      'Knowledge sharing platforms',
      'Cross-functional projects',
      'Peer support networks',
    ],
  },
  {
    title: 'Integrity & Trust',
    description: 'Maintaining the highest ethical standards',
    icon: Shield,
    examples: [
      'Transparent communication',
      'Ethical business practices',
      'Compliance excellence',
      'Client data protection',
    ],
  },
] as const;

/**
 * UIP's culture pillars that define our workplace
 */
export const CULTURE_PILLARS: readonly CulturePillar[] = [
  {
    title: 'Supportive Environment',
    description: 'Creating a workplace where everyone feels valued and supported',
    practices: [
      'Open-door leadership policy',
      'Regular team check-ins',
      'Wellness initiatives',
      'Recognition programs',
    ],
  },
  {
    title: 'Growth Mindset',
    description: 'Fostering continuous learning and professional development',
    practices: [
      'Personalized development plans',
      'Skill-building workshops',
      'Industry certifications',
      'Leadership training',
    ],
  },
  {
    title: 'Collaborative Spirit',
    description: 'Working together to achieve greater success',
    practices: [
      'Team-based projects',
      'Cross-training opportunities',
      'Mentorship programs',
      'Knowledge sharing sessions',
    ],
  },
] as const;