import { CORE_VALUES } from '../../../constants/company-values';

export default function CoreValues() {
  return (
    <section className="relative overflow-hidden bg-white py-24 sm:py-32">
      <div className="absolute inset-0 bg-gradient-to-br from-blue-50 to-white" />
      <div className="relative mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Our Core Values
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            These principles guide everything we do at UIP, from how we support our
            agents to how we serve our clients.
          </p>
        </div>

        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            {CORE_VALUES.map((value) => (
              <div key={value.title} className="flex flex-col">
                <dt className="flex items-center gap-x-3 text-lg font-semibold leading-7 text-gray-900">
                  <value.icon
                    className="h-6 w-6 flex-none text-blue-600"
                    aria-hidden="true"
                  />
                  {value.title}
                </dt>
                <dd className="mt-4 flex flex-auto flex-col">
                  <p className="flex-auto text-base leading-7 text-gray-600">
                    {value.description}
                  </p>
                  <ul className="mt-4 space-y-2 text-sm text-gray-600">
                    {value.examples.map((example) => (
                      <li key={example} className="flex items-center">
                        <span className="mr-2 h-1 w-1 flex-none rounded-full bg-blue-600" />
                        {example}
                      </li>
                    ))}
                  </ul>
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </section>
  );
}