import { 
  GraduationCap, 
  BarChart3, 
  Megaphone, 
  HeadphonesIcon,
  Laptop,
  Users,
  ArrowRight
} from 'lucide-react';
import { Link } from 'react-router-dom';
import Button from '../ui/Button';

const benefits = [
  {
    name: 'Industry-Leading Training',
    description: 'Access comprehensive training programs and ongoing education resources.',
    icon: GraduationCap,
  },
  {
    name: 'Advanced Technology',
    description: 'State-of-the-art CRM tools and digital platforms to streamline your business.',
    icon: Laptop,
  },
  {
    name: 'Marketing Resources',
    description: 'Ready-to-use marketing materials and customizable templates.',
    icon: Megaphone,
  },
  {
    name: 'Lead Programs',
    description: 'Access quality leads and proven lead generation strategies.',
    icon: Users,
  },
  {
    name: 'Performance Analytics',
    description: 'Track your success with detailed reporting and insights.',
    icon: BarChart3,
  },
  {
    name: '24/7 Support',
    description: 'Dedicated support team to help you succeed every step of the way.',
    icon: HeadphonesIcon,
  },
];

export default function AgentBenefits() {
  return (
    <section id="benefits" className="relative isolate overflow-hidden bg-white py-24 sm:py-32">
      <div className="absolute inset-0 -z-10 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.blue.50),white)]" />
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <div className="inline-flex items-center rounded-full bg-blue-50 px-4 py-1.5">
            <span className="text-sm font-medium text-blue-600">Why Choose UIP?</span>
          </div>
          <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Everything You Need to Succeed
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Join UIP and get access to industry-leading tools, training, and support
            designed to help you grow your insurance business.
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            {benefits.map((benefit) => (
              <div key={benefit.name} className="group relative">
                <div className="absolute -inset-4 rounded-2xl bg-blue-50/50 opacity-0 transition-opacity group-hover:opacity-100" />
                <div className="relative flex flex-col">
                  <div className="mb-6 flex h-12 w-12 items-center justify-center rounded-lg bg-blue-600 text-white">
                    <benefit.icon className="h-6 w-6" />
                  </div>
                  <dt className="text-lg font-semibold leading-7 text-gray-900">
                    {benefit.name}
                  </dt>
                  <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
                    <p className="flex-auto">{benefit.description}</p>
                  </dd>
                </div>
              </div>
            ))}
          </dl>
        </div>
        <div className="mt-16 flex justify-center">
          <Link to="/contact">
            <Button size="lg" className="gap-2 transition-transform hover:-translate-y-0.5">
              Get Started
              <ArrowRight className="h-5 w-5" />
            </Button>
          </Link>
        </div>
      </div>
    </section>
  );
}