import { Clock, Mail, Phone } from 'lucide-react';

export default function MaintenancePage() {
  return (
    <main className="grid min-h-screen place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <div className="flex justify-center">
          <Clock className="h-16 w-16 text-blue-600" />
        </div>
        
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          System Maintenance
        </h1>
        
        <p className="mt-6 text-base leading-7 text-gray-600">
          We're currently performing scheduled maintenance to improve our services.
          We'll be back shortly.
        </p>

        {/* Maintenance Details */}
        <div className="mx-auto mt-10 max-w-xl rounded-xl bg-gray-50 p-8">
          <dl className="space-y-6">
            <div>
              <dt className="text-sm font-medium text-gray-900">
                Estimated Duration
              </dt>
              <dd className="mt-1 text-sm text-gray-600">
                2 hours (Expected completion at 4:00 PM EST)
              </dd>
            </div>
            
            <div>
              <dt className="text-sm font-medium text-gray-900">
                System Status Updates
              </dt>
              <dd className="mt-1 text-sm text-gray-600">
                Follow us on Twitter @UIPInsurance for real-time updates
              </dd>
            </div>
          </dl>
        </div>

        {/* Contact Options */}
        <div className="mt-10 space-y-4">
          <h2 className="text-sm font-semibold text-gray-900">
            Need Immediate Assistance?
          </h2>
          
          <div className="flex justify-center gap-6">
            <a
              href="mailto:support@uipinsurance.com"
              className="flex items-center gap-2 text-sm text-gray-600 hover:text-blue-600"
            >
              <Mail className="h-4 w-4" />
              Email Support
            </a>
            
            <a
              href="tel:+18005551234"
              className="flex items-center gap-2 text-sm text-gray-600 hover:text-blue-600"
            >
              <Phone className="h-4 w-4" />
              (800) 555-1234
            </a>
          </div>
        </div>
      </div>
    </main>
  );
}