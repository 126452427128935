import { Building2, Mail, MapPin, Phone } from 'lucide-react';
import { useForm } from 'react-hook-form';
import Button from '../components/ui/Button';

type FormData = {
  name: string;
  email: string;
  company: string;
  message: string;
};

const contactInfo = [
  {
    icon: Phone,
    label: 'Phone',
    value: '+1 (555) 123-4567',
    href: 'tel:+15551234567',
  },
  {
    icon: Mail,
    label: 'Email',
    value: 'contact@uipinsurance.com',
    href: 'mailto:contact@uipinsurance.com',
  },
  {
    icon: Building2,
    label: 'Office',
    value: 'UIP Insurance Solutions',
  },
  {
    icon: MapPin,
    label: 'Address',
    value: '123 Business Ave, Suite 100, New York, NY 10001',
    href: 'https://maps.google.com',
  },
];

export default function ContactPage() {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<FormData>();

  const onSubmit = async (data: FormData) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      console.log('Form submitted:', data);
      reset();
      alert('Thank you for your message. We will get back to you soon!');
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('There was an error submitting your message. Please try again.');
    }
  };

  return (
    <main className="relative min-h-screen overflow-hidden bg-gradient-to-b from-gray-50 to-white pt-16">
      <div className="absolute inset-0 bg-grid-gray-900/[0.02] -z-10" />
      <div className="absolute inset-y-0 right-1/2 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] bg-white shadow-xl shadow-blue-600/10 ring-1 ring-blue-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />
      
      <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <div className="inline-flex items-center gap-2 rounded-full bg-blue-50 px-4 py-2 text-sm font-medium text-blue-700">
            <Mail className="h-4 w-4" />
            Let's Connect
          </div>
          <h1 className="mt-8 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Get in Touch
          </h1>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Have questions about our solutions? We're here to help and would love to hear from you.
          </p>
        </div>

        <div className="mx-auto mt-16 grid max-w-4xl grid-cols-1 gap-x-12 gap-y-16 lg:grid-cols-2">
          <div className="relative space-y-12">
            <div className="space-y-6">
              <h2 className="text-2xl font-bold text-gray-900">
                Contact Information
              </h2>
              <p className="text-gray-600">
                Reach out to us directly or fill out the form, and our team will get back to you promptly.
              </p>
            </div>

            <dl className="space-y-8">
              {contactInfo.map((item) => (
                <div
                  key={item.label}
                  className="group relative flex gap-6 rounded-lg bg-white p-4 transition-all hover:bg-blue-50"
                >
                  <dt className="flex-none">
                    <span className="sr-only">{item.label}</span>
                    <div className="rounded-lg bg-blue-50 p-2 text-blue-600 transition-colors group-hover:bg-white">
                      <item.icon className="h-6 w-6" aria-hidden="true" />
                    </div>
                  </dt>
                  <dd className="flex-auto">
                    <p className="font-medium text-gray-900">{item.label}</p>
                    {item.href ? (
                      <a
                        href={item.href}
                        className="mt-1 block text-gray-600 transition-colors hover:text-blue-600"
                        target={item.href.startsWith('http') ? '_blank' : undefined}
                        rel={item.href.startsWith('http') ? 'noopener noreferrer' : undefined}
                      >
                        {item.value}
                      </a>
                    ) : (
                      <p className="mt-1 text-gray-600">{item.value}</p>
                    )}
                  </dd>
                </div>
              ))}
            </dl>
          </div>

          <form
            onSubmit={handleSubmit(onSubmit)}
            className="relative overflow-hidden rounded-2xl bg-white p-8 shadow-xl ring-1 ring-gray-900/5 lg:p-10"
          >
            <div className="absolute inset-0 -z-10">
              <div className="absolute inset-0 bg-gradient-to-br from-blue-50 via-white to-white opacity-50" />
            </div>

            <div className="space-y-8">
              <div className="space-y-6">
                <div className="relative">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    {...register('name', { required: 'Name is required' })}
                    className={`mt-2 block w-full rounded-md border-0 px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset transition-shadow placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 ${
                      errors.name
                        ? 'ring-red-300 focus:ring-red-500'
                        : 'ring-gray-300'
                    }`}
                    placeholder="John Doe"
                  />
                  {errors.name && (
                    <p className="mt-2 text-sm text-red-600">{errors.name.message}</p>
                  )}
                </div>

                <div className="relative">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    {...register('email', {
                      required: 'Email is required',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: 'Invalid email address',
                      },
                    })}
                    className={`mt-2 block w-full rounded-md border-0 px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset transition-shadow placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 ${
                      errors.email
                        ? 'ring-red-300 focus:ring-red-500'
                        : 'ring-gray-300'
                    }`}
                    placeholder="john@example.com"
                  />
                  {errors.email && (
                    <p className="mt-2 text-sm text-red-600">{errors.email.message}</p>
                  )}
                </div>

                <div className="relative">
                  <label
                    htmlFor="company"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Company
                  </label>
                  <input
                    type="text"
                    id="company"
                    {...register('company', { required: 'Company is required' })}
                    className={`mt-2 block w-full rounded-md border-0 px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset transition-shadow placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 ${
                      errors.company
                        ? 'ring-red-300 focus:ring-red-500'
                        : 'ring-gray-300'
                    }`}
                    placeholder="Your Company"
                  />
                  {errors.company && (
                    <p className="mt-2 text-sm text-red-600">
                      {errors.company.message}
                    </p>
                  )}
                </div>

                <div className="relative">
                  <label
                    htmlFor="message"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Message
                  </label>
                  <textarea
                    id="message"
                    rows={4}
                    {...register('message', {
                      required: 'Message is required',
                      minLength: {
                        value: 10,
                        message: 'Message must be at least 10 characters',
                      },
                    })}
                    className={`mt-2 block w-full rounded-md border-0 px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset transition-shadow placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 ${
                      errors.message
                        ? 'ring-red-300 focus:ring-red-500'
                        : 'ring-gray-300'
                    }`}
                    placeholder="How can we help you?"
                  />
                  {errors.message && (
                    <p className="mt-2 text-sm text-red-600">
                      {errors.message.message}
                    </p>
                  )}
                </div>
              </div>

              <Button
                type="submit"
                disabled={isSubmitting}
                className="w-full justify-center gap-2 transition-transform hover:-translate-y-0.5"
                size="lg"
              >
                {isSubmitting ? 'Sending...' : 'Send Message'}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </main>
  );
}