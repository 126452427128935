import { Check, Minus } from 'lucide-react';

const products = [
  {
    name: 'Term Life',
    description: 'Affordable coverage for a specific period',
    features: {
      'Level Premiums': true,
      'Cash Value Growth': false,
      'Lifetime Coverage': false,
      'Premium Flexibility': false,
      'Death Benefit': true,
      'Living Benefits Available': true,
      'Policy Loans': false,
      'Convertibility': true,
    },
    bestFor: [
      'Temporary coverage needs',
      'Maximum coverage at lowest cost',
      'Young families',
      'Mortgage protection',
    ],
  },
  {
    name: 'Whole Life',
    description: 'Permanent protection with guaranteed benefits',
    features: {
      'Level Premiums': true,
      'Cash Value Growth': true,
      'Lifetime Coverage': true,
      'Premium Flexibility': false,
      'Death Benefit': true,
      'Living Benefits Available': true,
      'Policy Loans': true,
      'Convertibility': false,
    },
    bestFor: [
      'Lifetime protection',
      'Estate planning',
      'Business succession',
      'Cash value accumulation',
    ],
  },
  {
    name: 'Universal Life',
    description: 'Flexible permanent life insurance',
    features: {
      'Level Premiums': false,
      'Cash Value Growth': true,
      'Lifetime Coverage': true,
      'Premium Flexibility': true,
      'Death Benefit': true,
      'Living Benefits Available': true,
      'Policy Loans': true,
      'Convertibility': false,
    },
    bestFor: [
      'Flexible premium payments',
      'Adjustable coverage amounts',
      'Investment options',
      'Long-term savings goals',
    ],
  },
];

export default function ProductComparison() {
  return (
    <section className="py-16">
      <div className="mx-auto max-w-7xl">
        <div className="text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900">
            Compare Life Insurance Products
          </h2>
          <p className="mt-4 text-lg text-gray-600">
            Find the right coverage type for your clients' needs
          </p>
        </div>

        <div className="mt-16 overflow-hidden rounded-xl bg-white shadow-lg ring-1 ring-gray-200">
          <div className="min-w-full divide-y divide-gray-200">
            {/* Product Names */}
            <div className="grid grid-cols-4 divide-x divide-gray-200">
              <div className="px-6 py-4">
                <span className="text-sm font-medium text-gray-900">Features</span>
              </div>
              {products.map((product) => (
                <div key={product.name} className="px-6 py-4 text-center">
                  <h3 className="text-lg font-semibold text-gray-900">
                    {product.name}
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">{product.description}</p>
                </div>
              ))}
            </div>

            {/* Feature Comparisons */}
            {Object.keys(products[0].features).map((feature) => (
              <div
                key={feature}
                className="grid grid-cols-4 divide-x divide-gray-200"
              >
                <div className="px-6 py-4">
                  <span className="text-sm font-medium text-gray-900">
                    {feature}
                  </span>
                </div>
                {products.map((product) => (
                  <div
                    key={`${product.name}-${feature}`}
                    className="flex items-center justify-center px-6 py-4"
                  >
                    {product.features[feature as keyof typeof product.features] ? (
                      <Check className="h-5 w-5 text-green-500" />
                    ) : (
                      <Minus className="h-5 w-5 text-gray-300" />
                    )}
                  </div>
                ))}
              </div>
            ))}

            {/* Best For Section */}
            <div className="grid grid-cols-4 divide-x divide-gray-200">
              <div className="px-6 py-4">
                <span className="text-sm font-medium text-gray-900">Best For</span>
              </div>
              {products.map((product) => (
                <div key={`${product.name}-best-for`} className="px-6 py-4">
                  <ul className="space-y-2">
                    {product.bestFor.map((item) => (
                      <li
                        key={item}
                        className="flex items-center text-sm text-gray-600"
                      >
                        <span className="mr-2 h-1.5 w-1.5 flex-none rounded-full bg-blue-600" />
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}