import { FileText, Download, Video, Book } from 'lucide-react';
import Button from '../../ui/Button';

interface ResourceCenterProps {
  productType: 'life' | 'health' | 'medicare';
}

const resourceCategories = {
  life: {
    documents: [
      { name: 'Term Life Product Guide', type: 'PDF', size: '2.4 MB' },
      { name: 'Whole Life Sales Kit', type: 'PDF', size: '3.1 MB' },
      { name: 'Living Benefits Guide', type: 'PDF', size: '1.8 MB' },
      { name: 'Underwriting Guidelines', type: 'PDF', size: '2.2 MB' },
    ],
    training: [
      { name: 'Product Training Series', duration: '45 mins' },
      { name: 'Advanced Sales Techniques', duration: '60 mins' },
      { name: 'Living Benefits Overview', duration: '30 mins' },
      { name: 'Case Study Reviews', duration: '40 mins' },
    ],
    marketing: [
      { name: 'Client Presentation Templates', type: 'PPTX', size: '5.2 MB' },
      { name: 'Product Comparison Charts', type: 'PDF', size: '1.5 MB' },
      { name: 'Social Media Kit', type: 'ZIP', size: '8.3 MB' },
      { name: 'Email Templates', type: 'DOCX', size: '950 KB' },
    ],
  },
  health: {
    // Health insurance resources would go here
    documents: [],
    training: [],
    marketing: [],
  },
  medicare: {
    // Medicare resources would go here
    documents: [],
    training: [],
    marketing: [],
  },
};

export default function ResourceCenter({ productType }: ResourceCenterProps) {
  const resources = resourceCategories[productType];

  return (
    <section className="py-16">
      <div className="mx-auto max-w-7xl">
        <div className="text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900">
            Agent Resource Center
          </h2>
          <p className="mt-4 text-lg text-gray-600">
            Access product guides, training materials, and marketing resources
          </p>
        </div>

        <div className="mt-16 grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          {/* Product Documents */}
          <div className="rounded-2xl bg-white p-8 shadow-lg ring-1 ring-gray-200/50">
            <div className="flex items-center gap-4">
              <div className="rounded-lg bg-blue-50 p-2 text-blue-600">
                <FileText className="h-6 w-6" />
              </div>
              <h3 className="text-xl font-semibold text-gray-900">
                Product Documents
              </h3>
            </div>
            
            <ul className="mt-8 space-y-4">
              {resources.documents.map((doc) => (
                <li
                  key={doc.name}
                  className="flex items-center justify-between rounded-lg bg-gray-50 p-4 transition-colors hover:bg-gray-100"
                >
                  <div>
                    <p className="font-medium text-gray-900">{doc.name}</p>
                    <p className="text-sm text-gray-500">
                      {doc.type} • {doc.size}
                    </p>
                  </div>
                  <Button variant="outline" size="sm">
                    <Download className="h-4 w-4" />
                  </Button>
                </li>
              ))}
            </ul>
          </div>

          {/* Training Resources */}
          <div className="rounded-2xl bg-white p-8 shadow-lg ring-1 ring-gray-200/50">
            <div className="flex items-center gap-4">
              <div className="rounded-lg bg-blue-50 p-2 text-blue-600">
                <Video className="h-6 w-6" />
              </div>
              <h3 className="text-xl font-semibold text-gray-900">
                Training Resources
              </h3>
            </div>
            
            <ul className="mt-8 space-y-4">
              {resources.training.map((training) => (
                <li
                  key={training.name}
                  className="flex items-center justify-between rounded-lg bg-gray-50 p-4 transition-colors hover:bg-gray-100"
                >
                  <div>
                    <p className="font-medium text-gray-900">{training.name}</p>
                    <p className="text-sm text-gray-500">
                      Duration: {training.duration}
                    </p>
                  </div>
                  <Button variant="outline" size="sm">
                    Watch
                  </Button>
                </li>
              ))}
            </ul>
          </div>

          {/* Marketing Materials */}
          <div className="rounded-2xl bg-white p-8 shadow-lg ring-1 ring-gray-200/50">
            <div className="flex items-center gap-4">
              <div className="rounded-lg bg-blue-50 p-2 text-blue-600">
                <Book className="h-6 w-6" />
              </div>
              <h3 className="text-xl font-semibold text-gray-900">
                Marketing Materials
              </h3>
            </div>
            
            <ul className="mt-8 space-y-4">
              {resources.marketing.map((material) => (
                <li
                  key={material.name}
                  className="flex items-center justify-between rounded-lg bg-gray-50 p-4 transition-colors hover:bg-gray-100"
                >
                  <div>
                    <p className="font-medium text-gray-900">{material.name}</p>
                    <p className="text-sm text-gray-500">
                      {material.type} • {material.size}
                    </p>
                  </div>
                  <Button variant="outline" size="sm">
                    <Download className="h-4 w-4" />
                  </Button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}