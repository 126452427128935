import { useState } from 'react';
import { Calendar, Users, Award } from 'lucide-react';

const newsCategories = ['All', 'Company Updates', 'Industry News', 'Press Releases'];

const newsItems = [
  {
    title: 'UIP Expands Medicare Solutions Portfolio',
    category: 'Company Updates',
    date: '2024-03-01',
    description: 'Introducing new Medicare Advantage plans and enhanced support services.',
    image: 'https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?w=800&auto=format',
    author: 'Sarah Johnson',
    readTime: '3 min read'
  },
  {
    title: 'Industry Recognition: Best Insurance Workplace 2024',
    category: 'Press Releases',
    date: '2024-02-15',
    description: 'UIP named among top insurance workplaces for agent support and culture.',
    image: 'https://images.unsplash.com/photo-1507679799987-c73779587ccf?w=800&auto=format',
    author: 'Michael Chen',
    readTime: '4 min read'
  },
  {
    title: 'New Digital Tools for Insurance Agents',
    category: 'Company Updates',
    date: '2024-02-01',
    description: 'Launching innovative technology solutions to enhance agent productivity.',
    image: 'https://images.unsplash.com/photo-1551434678-e076c223a692?w=800&auto=format',
    author: 'Emily Thompson',
    readTime: '5 min read'
  }
];

export default function NewsPage() {
  const [selectedCategory, setSelectedCategory] = useState('All');

  const filteredNews = selectedCategory === 'All'
    ? newsItems
    : newsItems.filter(item => item.category === selectedCategory);

  return (
    <main className="min-h-screen bg-gray-50 pt-16">
      <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8">
        {/* Page Header */}
        <div className="text-center">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900">
            News & Updates
          </h1>
          <p className="mx-auto mt-6 max-w-2xl text-lg text-gray-600">
            Stay informed about UIP's latest developments, industry insights,
            and company announcements.
          </p>
        </div>

        {/* Category Filter */}
        <div className="mt-12 flex justify-center space-x-4">
          {newsCategories.map((category) => (
            <button
              key={category}
              onClick={() => setSelectedCategory(category)}
              className={`rounded-lg px-4 py-2 text-sm font-medium transition-colors
                ${selectedCategory === category
                  ? 'bg-blue-600 text-white'
                  : 'bg-white text-gray-700 hover:bg-gray-100'
              }`}
            >
              {category}
            </button>
          ))}
        </div>

        {/* News Grid */}
        <div className="mt-16 grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          {filteredNews.map((item) => (
            <article
              key={item.title}
              className="group relative overflow-hidden rounded-2xl bg-white shadow-lg ring-1 ring-gray-200/50 transition-all hover:shadow-xl"
            >
              {/* Image */}
              <div className="aspect-[16/9] overflow-hidden">
                <img
                  src={item.image}
                  alt={item.title}
                  className="h-full w-full object-cover transition-transform duration-300 group-hover:scale-105"
                />
              </div>

              {/* Content */}
              <div className="p-6">
                <div className="flex items-center gap-4">
                  <span className="rounded-full bg-blue-50 px-3 py-1 text-xs font-medium text-blue-600">
                    {item.category}
                  </span>
                  <time className="text-sm text-gray-500">
                    {new Date(item.date).toLocaleDateString()}
                  </time>
                </div>

                <h2 className="mt-4 text-xl font-semibold text-gray-900">
                  {item.title}
                </h2>
                
                <p className="mt-2 text-gray-600">
                  {item.description}
                </p>

                <div className="mt-6 flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <span className="text-sm font-medium text-gray-900">
                      {item.author}
                    </span>
                  </div>
                  <span className="text-sm text-gray-500">{item.readTime}</span>
                </div>
              </div>
            </article>
          ))}
        </div>
      </div>
    </main>
  );
}