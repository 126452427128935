import { Shield, Clock, DollarSign } from 'lucide-react';
import PlanCard from '../shared/PlanCard';

const plans = [
  {
    name: 'Term Life',
    description: 'Affordable coverage for a specific period',
    icon: Clock,
    features: [
      'Coverage periods: 10, 20, or 30 years',
      'Level premiums guaranteed',
      'Convertible to permanent coverage',
      'Death benefit protection'
    ],
    benefits: [
      'Most affordable option',
      'Flexible coverage periods',
      'Simple application process',
      'High coverage amounts available'
    ]
  },
  {
    name: 'Whole Life',
    description: 'Lifetime coverage with cash value growth',
    icon: Shield,
    features: [
      'Guaranteed lifetime coverage',
      'Level premiums',
      'Cash value accumulation',
      'Dividend potential'
    ],
    benefits: [
      'Permanent protection',
      'Tax-advantaged growth',
      'Access to cash value',
      'Estate planning tool'
    ]
  },
  {
    name: 'Universal Life',
    description: 'Flexible coverage and investment options',
    icon: DollarSign,
    features: [
      'Adjustable premiums',
      'Flexible death benefit',
      'Cash value growth potential',
      'Policy loan options'
    ],
    benefits: [
      'Premium flexibility',
      'Investment choices',
      'Death benefit adjustability',
      'Tax-advantaged savings'
    ]
  }
];

export default function IndividualPlans() {
  return (
    <div className="space-y-16">
      {/* Plans Grid */}
      <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
        {plans.map((plan) => (
          <PlanCard key={plan.name} plan={plan} />
        ))}
      </div>
    </div>
  );
}