export const LEADERSHIP_TEAM = [
  {
    name: 'Waylon Artrip',
    role: 'Chief Executive Officer',
    image: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?w=400&h=400&fit=crop&auto=format',
    bio: 'Visionary leader driving UIP\'s strategic growth and innovation initiatives.',
    achievements: [
      'Expanded operations to all 50 states',
      'Developed innovative agent support systems',
      'Established comprehensive training programs'
    ],
    social: {
      linkedin: '#',
      twitter: '#'
    }
  },
  {
    name: 'Samantha Mirenda',
    role: 'Chief Administrative Officer',
    image: 'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?w=400&h=400&fit=crop&auto=format',
    bio: 'Orchestrating operational excellence and organizational efficiency.',
    achievements: [
      'Streamlined operational processes',
      'Implemented advanced compliance systems',
      'Developed agent success programs'
    ],
    social: {
      linkedin: '#'
    }
  },
  {
    name: 'Michael Amsbaugh',
    role: 'President of Medicare Sales',
    image: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?w=400&h=400&fit=crop&auto=format',
    bio: 'Spearheading our Medicare division with industry-leading expertise.',
    achievements: [
      'Led Medicare sales growth of 200%',
      'Developed specialized training programs',
      'Expanded carrier partnerships'
    ],
    social: {
      linkedin: '#',
      twitter: '#'
    }
  },
  {
    name: 'Aarron Troche',
    role: 'President of Marketing',
    image: 'https://images.unsplash.com/photo-1519345182560-3f2917c472ef?w=400&h=400&fit=crop&auto=format',
    bio: 'Revolutionizing our digital presence and market strategies.',
    achievements: [
      'Launched digital marketing initiatives',
      'Created agent branding program',
      'Developed lead generation systems'
    ],
    social: {
      linkedin: '#',
      twitter: '#'
    }
  }
] as const;